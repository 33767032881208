import { ManufacturerCustomerSettings } from 'features/settings/models/ManufacturerCustomerSettings';
import { ApiTagType, api } from 'services/api';
import { z } from 'zod';
import { AccountSettings, AccountSettingsSchema } from './models/AccountSettings';
import { Bundle, BundleSchema } from './models/Bundle';
import { Company } from './models/Company';
import { DealerSettings, DealerSettingsSchema } from './models/DealerSettings';

export const settingsApi = api.injectEndpoints({
    endpoints: build => ({
        // Account Settings
        accountSettings: build.query<AccountSettings, void>({
            query: () => ({
                url: `/old/account-settings`,
                method: 'GET',
            }),
            transformResponse: (response: unknown) => AccountSettingsSchema.parse(response),
            providesTags: [ApiTagType.AccountSettings],
        }),

        /** Update settings */
        dealerSettings: build.query<DealerSettings, void>({
            query: () => ({
                url: `/old/account-settings/dealer-portal`,
                method: 'GET',
            }),
            transformResponse: (response: unknown) => DealerSettingsSchema.parse(response),
            providesTags: [ApiTagType.DealerSettings],
        }),

        /** Update settings */
        dealerSettingsUpdate: build.mutation<void, DealerSettings>({
            query: model => ({
                url: `/old/account-settings/dealer-portal`,
                method: 'POST',
                data: {
                    companyLogoDocumentId: model.companyLogoDocumentId,
                    companyDeliveryAddress: model.companyDeliveryAddress,
                    defaultShippingInstructions: model.defaultShippingInstructions,
                    companyDetails: model.companyDetails,
                    bindingText: model.bindingText,
                    depositPercentage: model.depositPercentage,
                    taxRatePercentage: model.taxRatePercentage,
                    accountAdjustmentType: model.accountAdjustmentType,
                    accountAdjustmentValue: model.accountAdjustmentValue,
                },
            }),
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of data
                dispatch(
                    settingsApi.util.updateQueryData('dealerSettings', undefined, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft, model);
                    }),
                );

                // invalidate list view
                dispatch(settingsApi.util.invalidateTags([ApiTagType.DealerSettings]));
            },
        }),

        /** main company Update */
        mainCompanyUpdate: build.mutation<void, Company>({
            query: model => ({
                url: `/old/account-settings/main-company`,
                method: 'POST',
                data: {
                    company_name: model.name,
                    address: model.address,
                    account_email: model.account_email,
                    order_confirmation_email: model.order_confirmation_email,
                    email: model.email,
                    phone: model.phone,
                },
            }),

            invalidatesTags: [ApiTagType.AccountSettings],
        }),

        /** Update settings */
        manufacturerCustomerSettingsUpdate: build.mutation<
            void,
            { manufacturerId: number; settings: ManufacturerCustomerSettings }
        >({
            query: args => ({
                url: `/old/account-settings/manufacturer/${args.manufacturerId}/customer-settings`,
                method: 'POST',
                data: {
                    default_shipping_method_id: args.settings.default_shipping_method_id,
                },
            }),
            invalidatesTags: [ApiTagType.AccountSettings],
        }),

        bundleList: build.query<Bundle[], void>({
            query: () => ({
                url: `/bundles`,
                method: 'GET',
            }),
            transformResponse: (response: unknown) => {
                const schema = z.array(BundleSchema);
                return schema.parse(response);
            },
            providesTags: [ApiTagType.Bundle],
        }),

        bundleSubscribe: build.mutation<void, { bundle: Bundle; optimistic: boolean }>({
            query: ({ bundle }) => ({
                url: `/bundles/${bundle.id}/subscribe`,
                method: 'POST',
                data: {
                    templateValues: bundle.templateFields?.reduce((hash, field) => {
                        if (field.value !== null) {
                            hash[field.templateKey] = field.value ?? '';
                        }
                        return hash;
                    }, {} as Record<string, number>),
                },
            }),
            async onQueryStarted({ bundle, optimistic }, { dispatch, queryFulfilled }) {
                if (!optimistic) {
                    // pessimistic update means run query before updating cache
                    await queryFulfilled;
                }

                // update cache
                const patchResult = dispatch(
                    settingsApi.util.updateQueryData('bundleList', undefined, draft => {
                        // update all fields of the current detail model
                        const record = draft.find(b => b.id === bundle.id);
                        if (record) {
                            Object.assign(record, {
                                isSubscribed: true,
                                templateFields: [...bundle.templateFields],
                            });
                        }
                    }),
                );

                try {
                    if (optimistic) {
                        // optimistic update means run query after updating cache
                        await queryFulfilled;
                    }
                } catch (e) {
                    // eslint-disable-next-line no-console
                    console.error(e);
                    patchResult.undo();
                }
            },
        }),

        bundleUnsubscribe: build.mutation<void, Bundle>({
            query: bundle => ({
                url: `/bundles/${bundle.id}/unsubscribe`,
                method: 'POST',
            }),

            async onQueryStarted(bundle, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of bundle object
                dispatch(
                    settingsApi.util.updateQueryData('bundleList', undefined, draft => {
                        // update all fields of the current detail model
                        const record = draft.find(b => b.id === bundle.id);
                        if (record) {
                            Object.assign(record, { isSubscribed: false });
                        }
                    }),
                );
            },
        }),
    }),
});

export default settingsApi;
