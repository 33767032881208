import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import BackendFilterStrip from 'components/BackendFilterStrip/BackendFilterStrip';
import DataTable, {
    ColumnBuilder,
    DataTableColumn,
    DataTableSortDirection,
} from 'components/DataTable/DataTable';
import DataTablePaging from 'components/DataTable/DataTablePaging';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import useSalesRepOptions from 'features/orders/helpers/useSalesRepOptions';
import PurchaseOrderStatus, {
    PurchaseOrderStatusDisplay,
} from 'features/purchases/enums/PurchaseOrderStatus';
import { PurchaseOrder } from 'features/purchases/models/PurchaseOrder';
import purchasesApi from 'features/purchases/purchases.api';
import { selectIsMultiSupplier, selectManufacturers } from 'features/settings/settings.slice';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import { useDataTableDynamicQuery } from 'hooks/useDataTableDynamicQuery';
import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ApiTagType } from 'services/api';
import { useAppSelector } from 'store/hooks';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import { BackendFilterBuilder } from '../../../../components/BackendFilterStrip/BackendFilterStrip';
import { ManufacturerOrderStatusBadge } from '../ManufacturerOrderStatusBadge/ManufacturerOrderStatusBadge';
import PurchaseOrderStatusBadge from '../PurchaseOrderStatusBadge/PurchaseOrderStatusBadge';
import './PurchaseOrdersTable.scss';

export default function PurchaseOrdersTable() {
    const [urlParams] = useSearchParams();
    const manufacturers = useAppSelector(selectManufacturers);
    const isMultiSupplier = useAppSelector(selectIsMultiSupplier);

    const supplierOptions = useMemo(
        () => manufacturers?.map(m => ({ label: m.name, value: m.id.toString() })) ?? [],
        [manufacturers],
    );
    const salesRepOptions = useSalesRepOptions({ includeNotSet: true });

    const criteriaFields = useMemo(
        () =>
            BackendFilterBuilder()
                .filter({
                    param: 'search',
                    label: 'Search',
                    type: 'search',
                    defaultValue: '',
                    urlParam: 'search',
                })
                .filter({
                    param: 'status',
                    label: 'Status',
                    type: 'select',
                    options: PurchaseOrderStatusDisplay.options,
                    defaultValue: '',
                    allowBlank: true,
                    urlParam: 'status',
                })

                .filter(
                    isMultiSupplier && {
                        param: 'manufacturer_id',
                        label: 'Supplier',
                        type: 'select',
                        options: supplierOptions,
                        defaultValue: '',
                        allowBlank: true,
                        urlParam: 'manufacturer',
                    },
                )
                .filter({
                    param: 'sales_rep_override',
                    label: 'Sales rep',
                    type: 'autocomplete',
                    options: salesRepOptions,
                    defaultValue: '',
                    urlParam: 'rep',
                })
                .filter({
                    param: 'date',
                    label: 'Date',
                    type: 'date',
                    range: 'past',
                    defaultValue: '-30',
                    urlParam: 'date',
                })
                .filter({
                    param: 'archived',
                    label: 'Archived',
                    type: 'toggle',
                    defaultValue: 'false',
                    urlParam: 'archived',
                })
                .build(),
        [isMultiSupplier, salesRepOptions, supplierOptions],
    );

    const { queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging } =
        useDataTableDynamicQuery(criteriaFields);

    const query = purchasesApi.usePurchaseOrderListQuery(queryParams);

    const refreshData = useApiTagInvalidate([ApiTagType.PurchaseOrder]);

    const handleSortChanged = useCallback(
        (col: DataTableColumn<PurchaseOrder>, direction: DataTableSortDirection) => {
            setQuerySort({
                propertyKey: col.key,
                direction,
            });
        },
        [setQuerySort],
    );

    const columns = useMemo(
        () =>
            ColumnBuilder<PurchaseOrder>()
                .column({
                    label: 'Order',
                    key: 'tuid',
                    getValue: item => item.tuid,
                })
                .column({
                    label: 'Status',
                    key: 'status',
                    render: item => <StatusCell item={item} />,
                })
                .column({
                    label: 'Supplier status',
                    key: 'supplierStatus',
                    render: item => <SupplierStatusCell item={item} />,
                })
                .column(
                    isMultiSupplier && {
                        label: 'Supplier',
                        key: 'supplier',
                        getValue: item =>
                            manufacturers?.find(m => item.manufacturerId === m.id)?.name,
                    },
                )
                .column({
                    label: 'Sidemark',
                    key: 'sidemark',
                    getValue: item => item.sidemark,
                })

                .column({
                    label: 'Sales rep',
                    key: 'salesRep',
                    render: item => item.salesRep,
                })
                .column({
                    label: 'Products',
                    key: 'products',
                    align: 'center',
                    getValue: item => (item.totalQuantity > 0 ? item.totalQuantity : null),
                })
                .column({
                    label: 'Total',
                    key: 'total',
                    getValue: (item: PurchaseOrder) =>
                        item.totalCost > 0 && formatCurrency(item.totalCost),
                })
                .column({
                    label: 'Date created',
                    key: 'createdAt',
                    render: item => formatDateTimeRelative(item.createdAt),
                })

                .build(),
        [isMultiSupplier, manufacturers],
    );

    return (
        <>
            <LayoutHeader>
                <div className="PurchasesTable__FilterBar">
                    <BackendFilterStrip
                        fields={criteriaFields}
                        onChange={setQueryCriteria}
                        onRefresh={refreshData}
                        isRefreshing={query.isFetching}
                    />
                </div>
            </LayoutHeader>
            <LayoutBody>
                <DataTable
                    className="PurchasesTable__DataTable"
                    isLoading={query.isLoading}
                    isError={query.isError}
                    data={query.data?.data}
                    rowLinkTo={item => `${item.id}?${urlParams}`}
                    zebra="light"
                    useFrontEndSorting={false}
                    onSortChanged={handleSortChanged}
                    columns={columns}
                    isRefreshing={query.isFetching}
                    useStickyHeader
                />
            </LayoutBody>

            <LayoutHeader>
                {(query.data?.total ?? 0) > 0 && (
                    <DataTablePaging
                        data={paging}
                        totalCount={query.data?.total}
                        onChange={setQueryPaging}
                    />
                )}
            </LayoutHeader>
        </>
    );
}

function StatusCell({ item }: { item: PurchaseOrder }) {
    return (
        <div className="PurchasesTable__StatusCell">
            <PurchaseOrderStatusBadge
                status={item.status}
                size="small"
            />

            {item.isArchived && <ArchivedBadge size="small" />}
        </div>
    );
}

function SupplierStatusCell({ item }: { item: PurchaseOrder }) {
    if (item.status !== PurchaseOrderStatus.Submitted) {
        return null;
    }
    return (
        <div className="PurchasesTable__SupplierStatusCell">
            {item.context.manufacturerOrderStatuses?.map((status, index) => (
                <ManufacturerOrderStatusBadge
                    key={index}
                    status={status}
                />
            ))}
        </div>
    );
}
