import DealDetailModal from 'features/deals/components/DealDetailModal/DealDetailModal';
import dealsApi from 'features/deals/deals.api';
import React, { useCallback } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePageTitle } from 'utils/locationHelpers';

export default function DealDetailPage() {
    const { dealId } = useParams() as { dealId: string };

    const navigate = useNavigate();

    const query = dealsApi.useDealDetailQuery(dealId);

    // use deal model to load workflow details
    const workflowId = query.data?.workflowId ?? '';
    const workflowQuery = dealsApi.useWorkflowDetailQuery(workflowId, {
        skip: !workflowId,
    });

    usePageTitle(query.data?.tuid);

    const handleClose = useCallback(() => {
        navigate(`..`);
    }, [navigate]);

    return dealId ? (
        <>
            <DealDetailModal
                key={dealId}
                model={query.data}
                workflow={workflowQuery.data}
                isLoading={query.isLoading || workflowQuery.isLoading}
                isError={query.isError || workflowQuery.isError}
                close={handleClose}
            />
            <Outlet />
        </>
    ) : null;
}
