import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import PageHeader from 'components/PageHeader/PageHeader';
import { WidgetDisplay } from 'features/dashboard/components/WidgetDisplay/WidgetDisplay';
import dashboardApi from 'features/dashboard/dashboard.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';

export default function DashboardPage() {
    usePageTitle('Dashboard');

    // Refresh data when the page is loaded
    useApiTagInvalidate([ApiTagType.Dashboard], { onMount: true });

    const query = dashboardApi.useDashboardDataQuery();
    return (
        <>
            <LayoutHeader>
                <PageHeader title="Dashboard" />
            </LayoutHeader>
            <LayoutBody autoPadding>
                <WidgetDisplay
                    data={query.data}
                    isLoading={query.isLoading}
                    isError={query.isError}
                />
            </LayoutBody>
            <Outlet />
        </>
    );
}
