import { Popover } from '@mui/material';
import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import FrontendFilterStrip, {
    FrontendFilterBuilder,
} from 'components/FrontendFilterStrip/FrontendFilterStrip';
import MyButton from 'components/MyButton/MyButton';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import dealsApi from 'features/deals/deals.api';
import { Deal } from 'features/deals/models/Deal';
import { DealDetail } from 'features/deals/models/DealDetail';
import { RelatedDeal } from 'features/deals/models/RelatedDeal';
import Icons from 'Icons';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import WorkflowStatusIcon from '../WorkflowStatusIcon/WorkflowStatusIcon';
import './DealRelatedDeals.scss';

export default function DealRelatedDeals({ model }: { model: DealDetail }) {
    const dialogManager = useDialogManager();

    const anchorEl = useRef<HTMLButtonElement | null>(null);
    const [isAddPopoverOpen, setIsAddPopoverOpen] = useState(false);

    const [relatedDealArchiveMutation] = dealsApi.useRelatedDealArchiveMutation();

    /** a set of related deal ids currently being archived - for showing loading spinners  */
    const relatedDealArchiveIds = useRef<Set<string>>(new Set<string>());

    const archiveRelatedDeal = useCallback(
        async (relatedDealId: string) => {
            const confirm = await dialogManager.confirm({
                message: `Are you sure you want to remove this relationship?`,
                acceptButtonType: 'Danger',
                acceptLabel: 'Yes, remove',
            });

            if (confirm) {
                relatedDealArchiveIds.current.add(relatedDealId);
                await relatedDealArchiveMutation({
                    dealId: model.id,
                    relatedDealId,
                }).unwrap();
                relatedDealArchiveIds.current.delete(relatedDealId);
            }
        },
        [dialogManager, model.id, relatedDealArchiveMutation],
    );

    const COLUMNS = ColumnBuilder<RelatedDeal>()
        .column({
            key: 'tuid',
            label: 'Id',
            render: item => (
                <div className="DealRelatedDeals__Id">
                    <span>{item.tuid}</span>
                    {item.isArchived && <ArchivedBadge size="small" />}
                </div>
            ),
        })
        .column({
            key: 'status',
            label: 'Status',
            render: item => (
                <div className="DealRelatedDeals__Status">
                    <WorkflowStatusIcon status={item.context?.status} />
                    {item.context?.status.name}
                </div>
            ),
        })
        .column({
            key: 'customerName',
            label: 'Customer',
            getValue: item => item.customerName,
        })
        .column({
            key: 'description',
            label: 'Description',
            render: item => <div className="DealRelatedDeals__Description">{item.description}</div>,
        })
        .column(
            !model.isArchived && {
                key: 'archive',
                label: 'Archive',
                align: 'right',
                className: 'DealRelatedDeals__ArchiveColumn',
                render: item => (
                    <MyButton
                        className="DealRelatedDeals__ArchiveButton"
                        onClick={async e => {
                            e.preventDefault();
                            archiveRelatedDeal(item.id);
                        }}
                        IconLeft={Icons.Close}
                        isLoading={relatedDealArchiveIds.current.has(item.id)}
                        buttonType="Nude"
                        size="small"
                        title="Remove relationship"
                    />
                ),
            },
        )
        .build();

    return (
        <>
            <SectionHeader
                title="Relationships"
                autoMargin
            />
            {model.context.relatedDeals.length > 0 && (
                <DataTable
                    className="DealRelatedDeals__DataTable"
                    columns={COLUMNS}
                    data={model.context.relatedDeals}
                    rowLinkTo={item => `/workflows/${item.context?.workflow.id}/${item.id}`}
                    showHeader="none"
                    rowStyle="skinny"
                    zebra="dark"
                    scrollable
                />
            )}
            {!model.isArchived && (
                <MyButton
                    className="DealRelatedDeals__AddButton"
                    ref={anchorEl}
                    label="Add relationship"
                    IconLeft={Icons.Plus}
                    buttonType="Hollow"
                    size="small"
                    onClick={() => setIsAddPopoverOpen(true)}
                />
            )}
            <AddDealPopover
                model={model}
                anchorEl={anchorEl}
                open={isAddPopoverOpen}
                onClose={() => setIsAddPopoverOpen(false)}
            />
        </>
    );
}

function AddDealPopover({
    model,
    anchorEl,
    open,
    onClose,
}: {
    model: DealDetail;
    anchorEl: React.MutableRefObject<HTMLButtonElement | null>;
    open: boolean;
    onClose?: () => void;
}) {
    const [relatedDealAddMutation] = dealsApi.useRelatedDealAddMutation();
    const [loading, setLoading] = useState(false);

    // call workflow list query to get workflow ids then pass it to useWorkflowDealListQuery to get all the workflow details
    const workflowQuery = dealsApi.useWorkflowListQuery();
    const [dealsQuery] = dealsApi.useLazyWorkflowDealListQuery();

    // Fetch the deals for each workflow ID
    const [allDeals, setAllDeals] = useState<Deal[]>([]);
    const [filteredData, setFilteredData] = useState<Deal[]>();

    const loadAllDeals = useCallback(async () => {
        if (workflowQuery.data) {
            setLoading(true);
            const promises = workflowQuery.data?.map(workflow =>
                dealsQuery(workflow.id, true).unwrap(),
            );

            const results = await Promise.all(promises);
            const deals = results.flatMap(result => result ?? []);
            setAllDeals(deals);
            setLoading(false);
        }
    }, [dealsQuery, workflowQuery.data]);

    useEffect(() => {
        loadAllDeals();
    }, [loadAllDeals, workflowQuery.data]);

    const [showTable, setShowTable] = useState(false);

    const close = useCallback(() => {
        setShowTable(false);
        onClose?.();
    }, [onClose]);

    const filters = useMemo(
        () =>
            FrontendFilterBuilder<Deal>()
                .filter({
                    type: 'search',
                    label: 'Search',
                    getFields: item => [item.tuid, item.customerName, item.description],
                    onChange: (config, val) => {
                        setShowTable(val.trim().length > 0);
                    },
                    autoFocus: true,
                    defaultValue: '',
                    className: 'DealRelatedDeals__AddDealPopover__SearchFilter',
                })
                .build(),
        [],
    );

    const isRowAdded = useCallback(
        (row: Deal) => {
            const isAdded = model.context.relatedDeals.some(
                relatedDeal => relatedDeal.id === row.id,
            );
            return isAdded;
        },
        [model.context.relatedDeals],
    );

    const COLUMNS = useMemo(
        () =>
            ColumnBuilder<Deal>()
                .column({
                    key: 'tuid',
                    label: 'ID',
                    getValue: deal => deal.tuid,
                    renderValue: (val, deal) => {
                        const isRelatedDealAdded = isRowAdded(deal);
                        return (
                            <>
                                <span
                                    className={coalesceClassNames(
                                        'DealRelatedDeals__AddedRow__CheckBox',
                                        isRelatedDealAdded && 'Checked',
                                    )}
                                >
                                    {isRelatedDealAdded ? (
                                        <Icons.Check className="checkIcon" />
                                    ) : (
                                        <Icons.Plus className="plusIcon" />
                                    )}
                                </span>
                                {val}
                            </>
                        );
                    },
                })
                .column({
                    key: 'customerName',
                    label: 'Customer',
                    getValue: deal => deal.customerName,
                })
                .column({
                    key: 'description',
                    label: 'Description',
                    render: deal => (
                        <div className="DealRelatedDeals__Description">{deal.description}</div>
                    ),
                })
                .build(),
        [isRowAdded],
    );

    return (
        <Popover
            open={open}
            onClose={close}
            anchorEl={anchorEl.current}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div className="DealRelatedDeals__AddDealPopover">
                <div className="DealRelatedDeals__AddDealPopover__Header">
                    <FrontendFilterStrip
                        className="DealRelatedDeals__AddDealPopover__Filters"
                        data={allDeals}
                        filters={filters}
                        onChange={setFilteredData}
                        allowReset={false}
                    >
                        <MyButton
                            className="DealRelatedDeals__AddDealPopover__CloseButton"
                            onClick={close}
                            buttonType="Nude"
                            IconRight={Icons.Close}
                        />
                    </FrontendFilterStrip>
                </div>

                {showTable && (
                    <DataTable
                        className="DealRelatedDeals__AddDealPopover__Table"
                        data={filteredData}
                        columns={COLUMNS}
                        rowClass={row => (isRowAdded(row) ? 'DealRelatedDeals__AddedRow' : '')}
                        onRowClick={row => {
                            relatedDealAddMutation({
                                dealId: model.id,
                                relatedDealId: row.id,
                            }).unwrap();
                            close();
                        }}
                        isLoading={loading}
                        showHeader="none"
                        rowStyle="skinny"
                        emptyState="No results found"
                        scrollable
                    />
                )}
            </div>
        </Popover>
    );
}
