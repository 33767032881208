import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './LayoutBody.scss';

export default function LayoutBody({
    className,
    children,
    autoPadding,
}: {
    className?: string;
    children: React.ReactNode;
    autoPadding?: boolean;
}) {
    return (
        <div
            className={coalesceClassNames(
                'LayoutBody',
                autoPadding && 'LayoutBody--autoPadding',
                className,
            )}
        >
            {children}
        </div>
    );
}
