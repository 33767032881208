import { dateTimeInstant } from 'utils/customSchemas';
import z from 'zod';

export const CustomerQuoteDetailSchema = z.object({
    id: z.string(),
    isDepositRequired: z.boolean(),
    order: z.object({
        id: z.number(),
        tuid: z.string(),
        customerName: z.string(),
        total: z.number(),
        depositAmount: z.number(),
        createdAt: dateTimeInstant(),
        convertedAt: dateTimeInstant().nullable(),
    }),
    quotePdf: z.object({
        id: z.number(),
        url: z.string(),
    }),
    company: z.object({
        id: z.number(),
        name: z.string(),
    }),
});

export type CustomerQuoteDetail = z.infer<typeof CustomerQuoteDetailSchema>;
