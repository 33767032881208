import LayoutBody from 'components/LayoutBody/LayoutBody';
import SupplierSettingsEditable from 'features/settings/components/SupplierSettingsEditable/SupplierSettingsEditable';
import { selectManufacturers } from 'features/settings/settings.slice';
import React from 'react';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

export default function SupplierPage() {
    usePageTitle('Supplier');
    const manufacturers = useAppSelector(selectManufacturers);

    return (
        <LayoutBody autoPadding>
            {manufacturers?.map(manufacturer => (
                <div key={manufacturer.id}>
                    <SupplierSettingsEditable manufacturer={manufacturer} />
                </div>
            ))}
        </LayoutBody>
    );
}
