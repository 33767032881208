import { CheckboxProps, FormControl, InputLabel, OutlinedInput, Switch } from '@mui/material';
import FieldValidator from 'components/FieldValidator/FieldValidator';
import React, { ChangeEvent } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MySwitchInput.scss';

export type MySwitchInputProps = {
    label?: string;
    checked?: boolean;
    handleInput?: (val: boolean) => void;
    className?: string;
    validationKey?: string;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
} & CheckboxProps;

export default function MySwitchInput({
    label,
    checked = false,
    handleInput,
    className,
    validationKey,
    validationRequired,
    validationCustom,
    ...props
}: MySwitchInputProps) {
    const onChange = (e: ChangeEvent<HTMLInputElement>, check: boolean) => {
        props.onChange?.(e, check);
        handleInput?.(check);
    };

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
            value={checked}
        >
            {({ revealError, error }) => (
                <FormControl
                    className={coalesceClassNames(
                        'MySwitchInput',
                        label ? 'MySwitchInput--with-label' : 'MySwitchInput--no-label',
                        className,
                        error && 'MySwitchInput--error',
                    )}
                    size="small"
                    variant={label ? 'outlined' : 'standard'}
                >
                    {label && (
                        <InputLabel
                            variant="outlined"
                            shrink={true}
                        >
                            {label}
                        </InputLabel>
                    )}
                    <OutlinedInput
                        notched={true}
                        label={label}
                        className="MySwitchInput__OutlinedInput"
                        type="hidden"
                    />

                    <div className="MySwitchInput__SwitchWrapper">
                        <Switch
                            {...props}
                            className="MySwitchInput__Switch"
                            checked={checked}
                            disableRipple
                            onChange={onChange}
                            onBlur={revealError}
                        />
                    </div>
                </FormControl>
            )}
        </FieldValidator>
    );
}
