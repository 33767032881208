import Env from 'config/Env';
import { http, HttpResponse } from 'msw';
import { wait } from 'utils/helpers';
import {
    fakeManufacturerOrders,
    fakeManufacturerOrderStatuses,
    fakePurchaseOrderDetails,
} from './faker/PurchaseOrderFaker';
import { ManufacturerOrderStatus } from './models/ManufacturerOrderStatus';
import { PurchaseOrder } from './models/PurchaseOrder';

const BASE_URL = `${Env.API_BASE_URL}`;

export const purchasesHandlers = [
    // purchaseOrderList
    http.post(`${BASE_URL}/purchaseorder`, async ({ request }) => {
        const body = (await request.json()) as {
            filters: {
                search: string;
                start: string;
                end: string;
                is_archived: boolean;
                sales_rep_override: string;
            };
            pagination: {
                limit: number;
                offset: number;
            };
        };

        // map detail model to list model
        const data = fakePurchaseOrderDetails
            .slice(body.pagination.offset, body.pagination.limit)
            .map((po): PurchaseOrder => {
                // get manufacturer orders and map to summaries
                const manufacturerOrderStatuses: ManufacturerOrderStatus[] = (
                    fakeManufacturerOrders[po.id] ?? []
                ).map(mfo => mfo.context.orderStatus);

                return {
                    isPurchase: po.isPurchase,
                    isQuote: po.isQuote,
                    status: po.status,
                    id: po.id,
                    tuid: po.tuid,
                    createdAt: po.createdAt,
                    isArchived: po.isArchived,
                    salesRep: po.salesRep,
                    sidemark: po.sidemark ?? '',
                    customerDetails: po.customerDetails ?? '',
                    totalCost: po.totalCost,
                    totalQuantity: po.totalQuantity,
                    manufacturerId: po.manufacturerId,
                    context: {
                        manufacturerOrderStatuses,
                    },
                };
            });

        const result = {
            data,
            total: fakePurchaseOrderDetails.length,
        };
        await wait(500);
        return HttpResponse.json(result);
    }),

    // purchase order details
    http.get(`${BASE_URL}/purchaseorder/:id`, async ({ params }) => {
        const id = params.id as string;
        const result = fakePurchaseOrderDetails.find(p => `${p.id}` === id);
        if (!result) {
            return new HttpResponse(`Purchase order not found`, { status: 400 });
        }
        await wait(500);
        return HttpResponse.json(result);
    }),

    // manufacturer orders
    http.get(`${BASE_URL}/purchaseorder/:id/manufacturerorders`, async ({ params }) => {
        const id = params.id as string;
        const po = fakePurchaseOrderDetails.find(p => `${p.id}` === id);
        if (!po) {
            return new HttpResponse(`Purchase order not found`, { status: 400 });
        }

        const result = fakeManufacturerOrders[po.id] ?? [];
        await wait(500);
        return HttpResponse.json(result);
    }),

    // manufacturer order statuses
    http.get(`${BASE_URL}/manufacturer/:id/order-statuses`, async () => {
        const result = fakeManufacturerOrderStatuses;
        await wait(500);
        return HttpResponse.json(result);
    }),
];
