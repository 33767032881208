import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import FrontendFilterStrip, {
    FrontendFilterBuilder,
} from 'components/FrontendFilterStrip/FrontendFilterStrip';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import { DocumentFriendlyTypeDisplay } from 'features/documents/enums/DocumentFriendlyType';
import { DocumentFile } from 'features/documents/models/DocumentFile';
import { selectManufacturers } from 'features/settings/settings.slice';
import React, { useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import DocumentFileIcon from '../DocumentFileIcon/DocumentFileIcon';
import './DocumentsTable.scss';

export default function DocumentsTable({
    data,
    isLoading,
    isError,
    onRefresh,
    isRefreshing,
    onRowClick,
}: {
    data?: DocumentFile[];
    isLoading?: boolean;
    isError?: boolean;
    onRefresh?: () => void;
    isRefreshing?: boolean;
    onRowClick?: (item: DocumentFile) => void;
}) {
    const [filteredData, setFilteredData] = useState<DocumentFile[]>();

    const manufacturers = useAppSelector(selectManufacturers);
    const manufacturerOptions = useMemo(
        () => manufacturers?.map(m => ({ label: m.name, value: `${m.id}` })) ?? [],
        [manufacturers],
    );

    const showManufacturerFields = manufacturerOptions.length > 1;

    const columns = useMemo(
        () =>
            ColumnBuilder<DocumentFile>()
                .column({
                    key: 'name',
                    label: 'Document name',
                    isSortable: true,
                    getValue: item => item.name,
                    renderValue: (val, doc) => (
                        <div className="DocumentsTable__NameCell">
                            <DocumentFileIcon type={doc.type} />
                            {val}
                        </div>
                    ),
                })
                .column({
                    key: 'type',
                    label: 'Type',
                    isSortable: true,
                    getValue: item => item.type,
                    renderValue: val => DocumentFriendlyTypeDisplay.display(val),
                })
                .column(
                    showManufacturerFields && {
                        key: 'manufacturer_name',
                        label: 'Supplier',
                        isSortable: true,
                        getValue: item => item.manufacturer_name,
                    },
                )
                .column({
                    key: 'created_at',
                    label: 'Created',
                    isSortable: true,
                    defaultSort: 'DESC',
                    getValue: item => item.created_at,
                    renderValue: val => formatDateTimeRelative(val),
                })
                .build(),
        [showManufacturerFields],
    );

    const filters = useMemo(
        () =>
            FrontendFilterBuilder<DocumentFile>()
                .filter({
                    type: 'search',
                    label: 'Search',
                    getFields: item => [item.name],
                    urlParam: 'search',
                })
                .filter({
                    type: 'select',
                    label: 'Type',
                    className: 'DocumentsTable__TypeFilter',
                    getField: item => item.type,
                    options: DocumentFriendlyTypeDisplay.options,
                    urlParam: 'type',
                })
                .filter(
                    showManufacturerFields && {
                        type: 'select',
                        label: 'Supplier',
                        getField: item => `${item.manufacturer_id}`,
                        options: manufacturerOptions,
                        urlParam: 'supplier',
                    },
                )
                .build(),
        [manufacturerOptions, showManufacturerFields],
    );

    return (
        <>
            <LayoutHeader>
                <FrontendFilterStrip
                    data={data}
                    onChange={setFilteredData}
                    filters={filters}
                    onRefresh={onRefresh}
                    isRefreshing={isRefreshing}
                />
            </LayoutHeader>

            <LayoutBody>
                <DataTable
                    className="DocumentsTable__DataTable"
                    data={filteredData}
                    isLoading={isLoading}
                    isError={isError}
                    columns={columns}
                    isRefreshing={isRefreshing}
                    emptyState="No documents found"
                    rowLinkTo={doc => doc.url}
                    rowLinkToTarget="_blank"
                    onRowClick={onRowClick}
                    zebra="light"
                    useStickyHeader
                />
            </LayoutBody>
        </>
    );
}
