import LayoutBody from 'components/LayoutBody/LayoutBody';
import DealerSettingsEditable from 'features/settings/components/DealerSettingsEditable/DealerSettingsEditable';
import settingsApi from 'features/settings/settings.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';

export default function SettingsPage() {
    usePageTitle('Settings');
    const query = settingsApi.useDealerSettingsQuery();

    // Refresh data when the page is loaded
    useApiTagInvalidate([ApiTagType.DealerSettings], { onMount: true });

    return (
        <LayoutBody autoPadding>
            <DealerSettingsEditable
                model={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
            />
        </LayoutBody>
    );
}
