import MyEditModal from 'components/MyEditModal/MyEditModal';
import {
    OrderLineEditable,
    OrderLineEditableEditableFactory,
} from 'features/orders/models/OrderLineEditable';
import ordersApi from 'features/orders/orders.api';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import { PurchaseOrderLine } from 'features/purchases/models/PurchaseOrderLine';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import { QuoteOrderLine } from 'features/quotes/models/QuoteOrderLine';
import React, { useMemo } from 'react';
import { StrictUnion } from 'utils/typeHelpers';
import OrderProductEditFields from '../OrderProductEditFields/OrderProductEditFields';
import ProductPathDisplay from '../ProductPathDisplay/ProductPathDisplay';
import './OrderProductEditModal.scss';

export default function OrderProductEditModal({
    model,
    order,
    close,
}: {
    model: StrictUnion<PurchaseOrderLine | QuoteOrderLine>;
    order: StrictUnion<PurchaseOrderDetail | QuoteDetail>;
    close?: () => void;
}) {
    const [saveMutation, saveMutationState] = ordersApi.useOrderProductUpdateMutation();

    const save = async (editModel: OrderLineEditable) => {
        await saveMutation(editModel).unwrap();
        close?.();
    };

    const legacyOrderId = order.externalId ?? order.id;

    const editableModel = useMemo(
        () =>
            OrderLineEditableEditableFactory.createFromOrderLine({
                orderLine: model,
                legacyOrderId,
            }),
        [model, legacyOrderId],
    );

    return (
        <MyEditModal
            className="OrderProductEditModal"
            title="Product Details"
            subtitle={<ProductPathDisplay product={model.context.product} />}
            mobileTitle={'Product'}
            model={editableModel}
            close={close}
            onSave={save}
            isSaving={saveMutationState.isLoading}
            editImmediately={true}
            fullHeight={false}
        >
            {({ editModel, updateField, isSaving }) => (
                <OrderProductEditFields
                    editModel={editModel}
                    order={order}
                    updateField={updateField}
                    isSaving={isSaving}
                />
            )}
        </MyEditModal>
    );
}
