import { BackendFilterStripConfig } from 'components/BackendFilterStrip/BackendFilterStrip';
import { DynamicQueryParams } from 'models/DynamicQueryParams';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import useDynamicQueryParams from './useDynamicQueryParams';

/** This hook wires up server side DynamicQuery filtering, paging and sorting for use with DataTable components
 * Because there are several components that need to be wired up to make this work, this hook does the heavy lifting for you
 * and in most cases you can just pass in criteria configs and then use the result to wire up your DataTable components
 */
export function useDataTableDynamicQuery<T extends DynamicQueryParams>(
    fields: BackendFilterStripConfig[],
    // columns: DataTableColumn<M>[],
    options?: {
        pageSize?: number;
        uppercaseSortBy?: boolean;
        defaultSort?: T['sort'];
        /** Any static data that should be inlcuded in the query but which doesnt have a criteria field connected
         * e.g manufacturer_id or user_id
         */
        staticValues?: Partial<T['criteria']>;
    },
) {
    const [searchParams] = useSearchParams();

    // create a clone of any static values as a base for the initial values reducer
    const staticValuesClone = { ...(options?.staticValues ?? {}) } as T['criteria'];

    /**
     * Compute the initial value of each field to pass into useDynamicQueryParams
     * This is then stored as a serialized JSON string
     * This way we can recalculate when fields change without triggering a full re-render
     * if the resulting config is actually the same
     */
    const _initialValuesJson = JSON.stringify(
        fields.reduce((hash, f) => {
            if (f) {
                // the initial value comes from the current url param val
                // otherwise the default value
                const val = f.urlParam
                    ? searchParams.get(f.urlParam) ?? f.defaultValue
                    : f.defaultValue;

                hash[f.param] = val ?? '';
            }
            return hash;
        }, staticValuesClone),
    );

    /** Re-parse the above json back to a hash of initial values
     * This is only triggered if the actual default values change
     */
    const initialValues = useMemo(() => {
        return JSON.parse(_initialValuesJson) as T['criteria'];
    }, [_initialValuesJson]);

    const [queryParams, setQueryCriteria, setQuerySort, paging, setQueryPaging] =
        useDynamicQueryParams<T>(initialValues, {
            pageSize: options?.pageSize,
            uppercaseSortBy: options?.uppercaseSortBy,
            defaultSort: options?.defaultSort,
        });

    return {
        /** the criteria field configs used to create this setup */
        fields,
        /** the current query params to be used in the api request */
        queryParams,
        /** onChange handler designed to be hooked up to BackendFilterStrip component */
        setQueryCriteria,
        /** onSortChange handler designed to be hooked up to DataTable component */
        setQuerySort,
        /** The current DynamicQueryPaging state */
        paging,
        /** onChange handler to update the DynamicQueryPaging state - designed to be used with the DataTablePaging component */
        setQueryPaging,
    } as const;
}
