import { Menu, PopoverOrigin } from '@mui/material';
import MyDrawer from 'components/MyDrawer/MyDrawer';
import React, { useState } from 'react';
import { IconType } from 'react-icons';
import coalesceClassNames, { extendClassName } from 'utils/coalesceClassNames';
import { useBreakpoints } from '../../providers/Breakpoints';
import MyButton, { ButtonType } from '../MyButton/MyButton';
import './MyMenuButton.scss';

export type MyMenuButtonItem = {
    label: string | React.ReactNode;
    buttonType?: ButtonType;
    IconLeft?: IconType;
    onClick?: () => void;
    disabled?: boolean;
    href?: string;
    LinkComponent?: React.ElementType<any>;
};

export type MyMenuButtonProps = {
    buttonType?: ButtonType;
    buttonText?: string;
    menuItems: (false | MyMenuButtonItem)[];
    className?: string;
    showDownIcon?: boolean;
    IconRight?: IconType;
    title?: string;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
};

export default function MyMenuButton({
    buttonType,
    buttonText = '',
    menuItems = [],
    className = '',
    IconRight,
    title = '',
    disabled,
    size = 'medium',
    anchorOrigin = {
        vertical: 'bottom',
        horizontal: 'right',
    },
    transformOrigin = {
        vertical: 'top',
        horizontal: 'right',
    },
}: MyMenuButtonProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const anchorRef = React.useRef<HTMLSpanElement>(null);
    const breakpoints = useBreakpoints();

    // close menu before firing onClick for regular menu items and radio buttons
    const handleItemClick = (e: React.UIEvent, item: MyMenuButtonItem) => {
        if (!item.href) {
            e.preventDefault();
        }
        setIsMenuOpen(false);
        item.onClick?.();
    };

    /** Close the menu opened */
    const onRequestClose = () => setIsMenuOpen(false);

    return (
        <>
            {/* Menu button - click this to open the menu */}
            <span
                className={coalesceClassNames('MyMenuButton', className, isMenuOpen && 'menu-open')}
                ref={anchorRef}
            >
                <MyButton
                    buttonType={buttonType}
                    onClick={e => {
                        e.preventDefault();
                        setIsMenuOpen(!isMenuOpen);
                    }}
                    title={title}
                    disabled={disabled}
                    label={buttonText}
                    IconRight={IconRight}
                    size={size}
                ></MyButton>
            </span>
            {/* Menu that appears when you click the button */}
            {breakpoints.isSmallDown ? (
                // Drawer on mobile
                <MyDrawer
                    className={extendClassName(className, '__Menu')}
                    isOpen={isMenuOpen}
                    onClose={onRequestClose}
                    header={title || buttonText}
                >
                    {menuItems.map(
                        (item, index) =>
                            item && (
                                <MenuItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    onClick={handleItemClick}
                                />
                            ),
                    )}
                </MyDrawer>
            ) : (
                // Dropdown on desktop
                <Menu
                    classes={{
                        paper: coalesceClassNames(
                            'MyMenuButton__Menu',
                            className && extendClassName(className, '__Menu'),
                        ),
                        list: 'MyMenuButton__MenuList',
                    }}
                    open={isMenuOpen}
                    onClose={(e: React.UIEvent) => {
                        e.preventDefault();
                        onRequestClose();
                    }}
                    anchorEl={anchorRef.current}
                    // positioning option see https://mui.com/material-ui/react-popover/#anchor-playground
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                >
                    {title && <div className="MyMenuButton__Menu__Title">{title}</div>}
                    {menuItems.map(
                        (item, index) =>
                            item && (
                                <MenuItem
                                    key={index}
                                    item={item}
                                    index={index}
                                    onClick={handleItemClick}
                                />
                            ),
                    )}
                </Menu>
            )}
        </>
    );
}

function MenuItem({
    item,
    index,
    onClick,
}: {
    item: MyMenuButtonItem;
    index: number;
    onClick: (e: React.UIEvent, item: MyMenuButtonItem) => void;
}) {
    return (
        <li
            key={index}
            className="MyMenuButton__MenuItem"
        >
            <MyButton
                className="MyMenuButton__MenuItemButton"
                buttonType={item.buttonType || 'None'}
                IconLeft={item.IconLeft}
                onClick={e => onClick(e, item)}
                disabled={item.disabled}
                href={item.href}
                LinkComponent={item.LinkComponent}
            >
                {item.label}
            </MyButton>
        </li>
    );
}
