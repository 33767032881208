import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import { DealEdit } from 'features/deals/models/DealEdit';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import React from 'react';
import WorkflowStatusSelect from '../WorkflowStatusSelect/WorkflowStatusSelect';
import './DealEditFields.scss';

export default function DealEditFields({
    editModel,
    workflow,
    isNew,
    isSaving,
    updateField,
}: {
    editModel: DealEdit;
    workflow: WorkflowDetail;
    isNew?: boolean;
    isSaving: boolean;
    updateField: (data: Partial<DealEdit>) => void;
}) {
    return (
        <div className="DealEditFields">
            <PropertyContainer
                layout="table"
                withInputSpacing
            >
                {isNew && (
                    <PropertyDisplay
                        label="Status"
                        value={
                            <WorkflowStatusSelect
                                workflow={workflow}
                                value={editModel.statusId}
                            />
                        }
                    />
                )}

                <PropertyEditText
                    label="Customer"
                    value={editModel?.customerName}
                    onChange={customerName => updateField({ customerName })}
                    disabled={isSaving}
                    validationRequired
                />
                <PropertyEditText
                    label="Description"
                    value={editModel?.description}
                    onChange={description => updateField({ description })}
                    disabled={isSaving}
                    multiline
                />
            </PropertyContainer>
        </div>
    );
}
