import MyAutocompleteInput, {
    MyAutocompleteInputOption,
} from 'components/MyAutocompleteInput/MyAutocompleteInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { FilterCompProps, FilterConfigBase } from '../FilterTypes';

export type AutocompleteFilterConfig = FilterConfigBase & {
    type: 'autocomplete';
    options?: MyAutocompleteInputOption[];
};

export default function AutocompleteFilter({
    filter,
    onChange,
}: FilterCompProps<AutocompleteFilterConfig>) {
    const [open, setOpen] = useState(false);

    useEffect(
        () => {
            // Auto-expand the filter to prompt for a value
            if (!filter.config.isSticky && !filter.value) {
                setOpen(true);
            }
        },
        // Only trigger the auto-expand when the dropdown first renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const handleChange = (val: string) => {
        filter.value = val;
        onChange?.(filter);
        setOpen(false);
    };
    return (
        <div
            className={coalesceClassNames(
                'FilterStrip__Field',
                'FilterStrip__Field--AutocompleteFilter',
                filter.config.className,
            )}
        >
            <MyAutocompleteInput
                label={filter.config.label}
                value={filter.value}
                open={open}
                options={filter.config.options}
                onChange={handleChange}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
            />
        </div>
    );
}
