import MySelectInput, { MySelectInputOption } from 'components/MySelectInput/MySelectInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { FilterCompProps, FilterConfigBase } from '../FilterTypes';

export type SelectFilterConfig = FilterConfigBase & {
    type: 'select';
    options?: MySelectInputOption[];
    allowBlank?: boolean;
};

export default function SelectFilter({ filter, onChange }: FilterCompProps<SelectFilterConfig>) {
    const [open, setOpen] = useState(false);

    const handleInput = (val: string) => {
        filter.value = val;
        onChange?.(filter);
        setOpen(false);
    };

    useEffect(
        () => {
            // Auto-expand the filter to prompt for a value
            if (!filter.config.isSticky && !filter.value) {
                setOpen(true);
            }
        },
        // Only trigger the auto-expand when the dropdown first renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return (
        <div
            className={coalesceClassNames(
                'FilterStrip__Field',
                'FilterStrip__Field--SelectFilter',
                filter.config.className,
            )}
        >
            <MySelectInput
                label={filter.config.label}
                value={filter.value ?? ''}
                open={open}
                options={filter.config.options}
                handleInput={handleInput}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                allowBlank={filter.config.allowBlank ?? true}
                key={`${filter.value}-${filter.config.isSticky}`}
            />
        </div>
    );
}
