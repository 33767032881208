import { ApiTagType, api } from 'services/api';
import z from 'zod';
import { Quote, QuoteSchema } from './models/Quote';
import { QuoteDetail, QuoteDetailSchema } from './models/QuoteDetail';
import { QuoteEditable } from './models/QuoteEditable';

const quotesApi = api.injectEndpoints({
    endpoints: build => ({
        quoteList: build.query<
            Quote[],
            {
                dealId: string;
                archived: boolean;
            }
        >({
            query: ({ dealId, archived }) => ({
                url: `/deals/${dealId}/quotes?archived=${archived}`,
                method: 'GET',
                data: {},
            }),
            transformResponse: (result: unknown) => z.array(QuoteSchema).parse(result),
            providesTags: [ApiTagType.Quote],
        }),

        quoteDetail: build.query<QuoteDetail, string>({
            query: id => ({
                url: `/quotes/${id}`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const obj = QuoteDetailSchema.parse(result);
                return obj;
            },
            providesTags: (res, err, id) => [{ type: ApiTagType.Quote, id }],
        }),

        quoteCreate: build.mutation<string, { dealId: string; model: QuoteEditable }>({
            query: ({ dealId, model }) => ({
                url: `/deals/${dealId}/quotes/legacy/add`,
                method: 'POST',
                data: {
                    description: model.description,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    id: z.string(),
                });
                return schema.parse(result).id;
            },
            invalidatesTags: [ApiTagType.Quote],
        }),

        /** Update an order - Quotes/Drafts only */
        quoteUpdate: build.mutation<void, QuoteEditable>({
            query: model => ({
                url: `/quotes/${model.id}/update`,
                method: 'POST',
                data: {
                    description: model.description,
                },
            }),
            async onQueryStarted(model, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    quotesApi.util.updateQueryData('quoteDetail', model.id, draft => {
                        // update all fields of the current detail model
                        Object.assign(draft, model);
                    }),
                );

                // invalidate list view
                dispatch(quotesApi.util.invalidateTags([ApiTagType.Quote]));
            },
        }),

        /** Apply a total price override to an an order - Quotes only */
        quoteApplyPriceOverride: build.mutation<
            void,
            {
                externalId: number;
                quoteId: string;
                overrideTotal: number | null;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.externalId}/manage-prices/apply-override-total`,
                method: 'POST',
                data: {
                    override_total: args.overrideTotal,
                    notes: '',
                    override_taxables: null,
                },
            }),
            async onQueryStarted(args, { dispatch, queryFulfilled }) {
                await queryFulfilled;

                // Pessimistic update of details object
                dispatch(
                    quotesApi.util.updateQueryData('quoteDetail', args.quoteId, draft => {
                        // update all fields of the current detail model
                        draft.totalSellPriceOverride = args.overrideTotal;
                    }),
                );

                // invalidate list view
                dispatch(quotesApi.util.invalidateTags([ApiTagType.Quote]));
            },
        }),

        /** Clone a quote */
        quoteClone: build.mutation<
            string,
            {
                id: string;
                description: string;
            }
        >({
            query: args => ({
                url: `/quotes/${args.id}/clone`,
                method: 'POST',
                data: {
                    description: args.description,
                },
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    id: z.string(),
                });
                return schema.parse(result).id;
            },
            invalidatesTags: [ApiTagType.Quote],
        }),

        quoteArchive: build.mutation<void, string>({
            query: id => ({
                url: `/quotes/${id}/archive`,
                method: 'POST',
            }),
            invalidatesTags: [ApiTagType.Quote],
        }),

        quoteUnarchive: build.mutation<void, string>({
            query: id => ({
                url: `/quotes/${id}/unarchive`,
                method: 'POST',
            }),
            invalidatesTags: [ApiTagType.Quote],
        }),

        quoteAccept: build.mutation<void, string>({
            query: id => ({
                url: `/quotes/${id}/accept`,
                method: 'POST',
            }),
            invalidatesTags: [ApiTagType.Quote],
        }),

        /** Get a PDF version of the order preview */
        quoteGeneratePdf: build.query<
            string,
            {
                orderId: string;
            }
        >({
            query: args => ({
                url: `quotes/${args.orderId}/pdf`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => {
                const schema = z.object({
                    url: z.string(),
                });
                return schema.parse(result).url;
            },
        }),

        /** Submit quote and create and submit a purchase order to the manufacturer */
        quoteSubmit: build.mutation<
            void,
            {
                orderId: number;
                requiredDate: string;
                shippingAddress: string;
                shippingMethodId: number;
            }
        >({
            query: args => ({
                url: `/old/quotes/${args.orderId}/convert`,
                method: 'POST',
                data: {
                    type: 'order',
                    process_type: '',
                    orderOnline: true,
                    required_date: args.requiredDate,
                    shipping_address: args.shippingAddress,
                    shipping_method_id: args.shippingMethodId,
                },
            }),
            invalidatesTags: [ApiTagType.Quote],
        }),
    }),
});

export default quotesApi;
