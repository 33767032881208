import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import MyTabs, { TabBuilder } from 'components/MyTabs/MyTabs';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import dealsApi from 'features/deals/deals.api';
import { DealDetail } from 'features/deals/models/DealDetail';
import { DealNote, DealNoteFactory } from 'features/deals/models/DealNote';
import { FlagValue } from 'features/deals/models/FlagValue';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import { WorkflowStatus } from 'features/deals/models/WorkflowStatus';
import useUrlQueryState from 'hooks/useUrlQueryState';
import Icons from 'Icons';
import { useBreakpoints } from 'providers/Breakpoints';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import { isEmpty } from 'utils/helpers';
import DealEditModal from '../DealEditModal/DealEditModal';
import DealFlagSelect from '../DealFlagSelect/DealFlagSelect';
import DealNoteEditModal from '../DealNoteEditModal/DealNoteEditModal';
import WorkflowStatusSelect from '../WorkflowStatusSelect/WorkflowStatusSelect';
import './DealDetailModal.scss';
import { DealQuotesTab } from './DealQuotesTab';
import DealRelatedDeals from './DealRelatedDeals';

export default function DealDetailModal({
    model,
    workflow,
    isLoading,
    isError,
    close,
}: {
    model?: DealDetail;
    workflow?: WorkflowDetail;
    isLoading: boolean;
    isError: boolean;
    close: () => void;
}) {
    const dialogManager = useDialogManager();
    const [archiveMutation] = dealsApi.useDealArchiveMutation();
    const [unArchiveMutation] = dealsApi.useDealUnarchiveMutation();

    const archiveDeal = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Archive`,
            message: `Are you sure you want to archive this item?`,
            acceptButtonType: 'Danger',
            acceptLabel: 'Yes, archive now',
        });

        if (model && confirm) {
            await dialogManager.showLoadingWhile(archiveMutation(model.id).unwrap());
            close?.();
        }
    }, [archiveMutation, close, dialogManager, model]);

    const unArchiveDeal = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Unarchive`,
            message: `Are you sure you want to unarchive this item?`,
            acceptButtonType: 'Primary',
            acceptLabel: 'Yes, unarchive now',
        });

        if (model && confirm) {
            await dialogManager.showLoadingWhile(unArchiveMutation(model.id).unwrap());
        }
    }, [dialogManager, model, unArchiveMutation]);

    const menuItems: MyMenuButtonItem[] = useMemo(
        () => [
            model?.isArchived
                ? {
                      label: 'Unarchive',
                      IconLeft: Icons.Archive,
                      onClick: unArchiveDeal,
                  }
                : {
                      label: 'Archive',
                      IconLeft: Icons.Archive,
                      onClick: archiveDeal,
                  },
        ],
        [archiveDeal, model?.isArchived, unArchiveDeal],
    );

    const [tab, setTab] = useUrlQueryState('tab', {
        allowedValues: ['quotes'],
    });

    const tabs = useMemo(
        () =>
            TabBuilder([
                {
                    name: 'quotes',
                    label: 'Quotes',
                    content: model && <DealQuotesTab model={model} />,
                },
            ]),
        [model],
    );

    const breakpoints = useBreakpoints();

    return (
        <>
            <MyModal
                className="DealDetailModal"
                close={close}
                isLoading={isLoading}
                isError={isError}
                mobileTitle={workflow?.name}
                fullHeight
                header={
                    <PageHeader
                        className="DealDetailModal__PageHeader"
                        title={workflow?.name}
                        titleContext={
                            <div className="DealDetailModal__PageHeader__TitleContext">
                                <div className="DealDetailModal__PageHeader__Tuid">
                                    {model?.tuid}
                                </div>

                                {model?.isArchived && <ArchivedBadge size="large" />}
                            </div>
                        }
                    >
                        <MyMenuKebabButton menuItems={menuItems} />
                    </PageHeader>
                }
            >
                {model && workflow && (
                    <>
                        <div className="DealDetailModal__Content">
                            <div className="DealDetailModal__Content__Main">
                                <Details model={model} />

                                {/* On small layout, flags appear in the main details  */}
                                {breakpoints.isSmallDown && (
                                    <Flags
                                        model={model}
                                        workflow={workflow}
                                    />
                                )}

                                <Notes
                                    dealId={model.id}
                                    isArchived={model.isArchived}
                                />

                                <DealRelatedDeals model={model} />

                                <div className="DealDetailModal__Payments">
                                    <SectionHeader
                                        title="Payments"
                                        autoMargin
                                    />
                                    TODO
                                </div>

                                <MyTabs
                                    className="DealDetailModal__Tabs"
                                    tabs={tabs}
                                    activeTab={tab}
                                    setActiveTab={setTab}
                                />
                            </div>
                            {/* On medium-up layout, flags appear in a sidebar  */}
                            {breakpoints.isMediumUp && (
                                <div className="DealDetailModal__Content__Sidebar">
                                    <Flags
                                        model={model}
                                        workflow={workflow}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </MyModal>
        </>
    );
}

function Details({ model }: { model: DealDetail }) {
    const [isEditing, setIsEditing] = useState(false);

    return (
        <div className="DealDetailModal__Details">
            <PropertyContainer layout="table">
                <PropertyDisplay
                    label="Customer"
                    value={model?.customerName}
                />
                <PropertyDisplay
                    label="Description"
                    value={model?.description}
                    verticalAlign="top"
                />
            </PropertyContainer>

            {!model.isArchived && (
                <MyButton
                    className="DealDetailModal__Details__EditButton"
                    label="Edit"
                    buttonType="Hollow"
                    size="small"
                    onClick={() => setIsEditing(true)}
                />
            )}

            {model && isEditing && (
                <DealEditModal
                    model={model}
                    close={() => setIsEditing(false)}
                />
            )}
        </div>
    );
}

function Flags({ model, workflow }: { model: DealDetail; workflow: WorkflowDetail }) {
    const [saveMutation] = dealsApi.useDealTagUpdateMutation();

    const saveFlagValue = useCallback(
        (flagId: string, flagValue?: FlagValue) => {
            if (flagValue) {
                saveMutation({
                    workflowId: workflow.id,
                    dealId: model.id,
                    flagId,
                    value: flagValue,
                });
            }
        },
        [model.id, saveMutation, workflow.id],
    );

    const dialogManager = useDialogManager();
    const [updateMutation] = dealsApi.useDealStatusUpdateMutation();
    const saveStatusValue = useCallback(
        async (statusValue?: WorkflowStatus) => {
            if (statusValue) {
                const result = await updateMutation({
                    id: model.id,
                    currentStatusId: model.statusId,
                    targetStatusId: statusValue.id,
                }).unwrap();
                if (result === 'conflict') {
                    dialogManager.toast({
                        title: "Oops! That didn't work",
                        icon: 'wave',
                        message: 'We had trouble making that change, please try again',
                    });
                }
            }
        },
        [updateMutation, model.id, model.statusId, dialogManager],
    );

    return (
        <div className="DealDetailModal__Flags">
            <PropertyContainer>
                {/* Status above other flags */}
                <PropertyDisplay
                    label="Status"
                    value={
                        <WorkflowStatusSelect
                            workflow={workflow}
                            value={model.statusId}
                            handleInput={saveStatusValue}
                            disabled={model.isArchived}
                        />
                    }
                />
                {workflow.context.flags.map(flag => {
                    const value = model.context.flagValues.find(
                        t => t.workflowFlagId === flag.id,
                    )?.workflowFlagValueId;
                    return (
                        <PropertyDisplay
                            key={flag.id}
                            label={flag.name}
                            value={
                                <DealFlagSelect
                                    label={flag.name}
                                    value={value}
                                    flag={flag}
                                    handleInput={val => saveFlagValue(flag.id, val)}
                                    disabled={model.isArchived}
                                />
                            }
                        />
                    );
                })}
            </PropertyContainer>
        </div>
    );
}

function Notes({ dealId, isArchived }: { dealId: string; isArchived: boolean }) {
    const query = dealsApi.useDealNoteListQuery(dealId);

    const [editNoteId, setEditNoteId] = useState<string>();
    const editModel = useMemo((): DealNote | undefined => {
        if (editNoteId === 'add') {
            return DealNoteFactory.create();
        }

        return editNoteId ? query.data?.find(n => n.id === editNoteId) : undefined;
    }, [editNoteId, query.data]);

    return (
        <div className="DealDetailModal__Notes">
            <SectionHeader
                title="Notes"
                autoMargin
            />
            {query.isError ? (
                <ErrorContent />
            ) : query.isLoading ? (
                <MyLinearProgress />
            ) : (
                <>
                    {!isEmpty(query.data) && (
                        <div className="DealDetailModal__Notes__List">
                            {query.data?.map(n => {
                                const user = n.context.updatedBy ?? n.context.createdBy;
                                return (
                                    <div
                                        key={n.id}
                                        className="DealDetailModal__Note"
                                    >
                                        <div className="DealDetailModal__Note__Content">
                                            {n.content}
                                        </div>
                                        <div className="DealDetailModal__Note__Footer">
                                            <div className="DealDetailModal__Note__Footer__Author">
                                                {user?.name}
                                            </div>
                                            <div className="DealDetailModal__Note__Footer__Date">
                                                {formatDateTimeRelative(n.updatedAt ?? n.createdAt)}
                                            </div>
                                            <a
                                                className="Link"
                                                onClick={() => setEditNoteId(n.id)}
                                            >
                                                Edit
                                            </a>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {!isArchived && (
                        <MyButton
                            className="DealDetailModal__Notes__AddButton"
                            IconLeft={Icons.Plus}
                            label="Add note"
                            buttonType="Hollow"
                            size="small"
                            onClick={() => setEditNoteId('add')}
                        />
                    )}
                </>
            )}

            {editModel && (
                <DealNoteEditModal
                    dealId={dealId}
                    model={editModel}
                    close={() => setEditNoteId(undefined)}
                />
            )}
        </div>
    );
}
