import { Autocomplete, AutocompleteValue, Checkbox, FormControl, TextField } from '@mui/material';
import React, { MutableRefObject, useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { isEmpty } from 'utils/helpers';
import FieldValidator from '../FieldValidator/FieldValidator';
import './MyAutocompleteInput.scss';

export type MyAutocompleteInputOption = {
    label: string;
    value: string;
};

export type MyAutocompleteInputProps = {
    id?: string;
    className?: string;
    label?: string;
    value?: string;
    open?: boolean;
    onChangeMultiple?: (val: MyAutocompleteInputOption[]) => void;
    onChange?: (val: string) => void;
    onOpen?: (event: React.SyntheticEvent) => void;
    onClose?: (event: React.SyntheticEvent) => void;
    validationKey?: string;
    validationRequired?: boolean | string;
    validationCustom?: false | string;
    options?: MyAutocompleteInputOption[];
    disabled?: boolean;
    multiple?: boolean;
    size?: 'small' | 'medium';
    /** Allow use to type options that are not in the list - value will be a string */
    freeSolo?: boolean;
    /** Tabbing out of the field caused the current value to be selected */
    autoSelect?: boolean;
    /** Whether to give focus to the component when it's rendered */
    autoFocus?: boolean;
};

export default function MyAutocompleteInput({
    id,
    className,
    label,
    value,
    open,
    onChange,
    onChangeMultiple,
    onOpen,
    onClose,
    validationKey,
    validationRequired,
    validationCustom,
    options = [],
    disabled = false,
    multiple = false,
    size = 'small',
    freeSolo = false,
    autoSelect = false,
    autoFocus = false,
}: MyAutocompleteInputProps) {
    // TODO check if it is necessary to declare the type of `val` this crazily in handleChange
    // Typescript/eslint is complaining if we don't declare them like this
    // I think it's because of freeSolo - Maybe we can do better?
    const handleChange = (
        val: AutocompleteValue<
            | string
            | MyAutocompleteInputOption
            | (MyAutocompleteInputOption | undefined)[]
            | undefined,
            boolean, // Multiple
            boolean, // DisableClearable
            boolean // FreeSolo
        >,
    ) => {
        if (multiple) {
            let selectedOpts;
            if (Array.isArray(val)) {
                selectedOpts = val.filter(Boolean) as MyAutocompleteInputOption[];
            } else {
                selectedOpts = [val].filter(Boolean) as MyAutocompleteInputOption[];
            }
            onChangeMultiple?.(selectedOpts);
        } else {
            const o = Array.isArray(val) ? val[0] : val;
            const strVal =
                typeof o === 'string'
                    ? o
                    : Array.isArray(o)
                    ? o.map(oo => oo?.value).join(',')
                    : o?.value ?? '';
            onChange?.(strVal);
        }
    };

    const selectedOption = useMemo(() => {
        const result = isEmpty(value) ? undefined : options.find(o => o.value === value);
        return freeSolo ? result ?? value : result ?? null;
    }, [options, value, freeSolo]);

    const selectedOptArray = useMemo(() => {
        const arr = Array.isArray(value) ? value : isEmpty(value) ? [] : [value];
        return arr.map(val => options.find(o => o.value === `${val}`)).filter(Boolean);
    }, [options, value]);

    const textFieldRef: MutableRefObject<HTMLInputElement | null> = React.useRef(null);

    React.useEffect(() => {
        if (autoFocus && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [autoFocus]);

    return (
        <FieldValidator
            validationKey={validationKey}
            validationRequired={validationRequired}
            validationCustom={validationCustom}
            value={value}
        >
            {({ revealError, error }) => (
                <FormControl
                    className={coalesceClassNames(
                        'MyAutocompleteInput',
                        className,
                        error && 'MyAutocompleteInput--error',
                        multiple && 'MyAutocompleteInput--multiple',
                        label ? 'MyAutocompleteInput--with-label' : 'MyAutocompleteInput--no-label',
                    )}
                    size={size}
                >
                    <Autocomplete
                        id={id}
                        className={coalesceClassNames(
                            'MyAutocompleteInput__Autocomplete',
                            className && `${className}__Autocomplete`,
                        )}
                        options={options}
                        disabled={disabled}
                        size={size}
                        value={multiple ? selectedOptArray : selectedOption}
                        multiple={multiple}
                        freeSolo={freeSolo}
                        // autoSelect should always be true when using freeSolo
                        // otherwise new values that you type will not be applied when tabbing out
                        autoSelect={freeSolo || autoSelect}
                        renderInput={params => (
                            <TextField
                                {...params}
                                label={label}
                                className="MyAutocompleteInput__TextField"
                                inputRef={ref => {
                                    textFieldRef.current = ref;
                                }}
                            />
                        )}
                        disableCloseOnSelect={multiple}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                {multiple && (
                                    <Checkbox
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                )}
                                {typeof option === 'string'
                                    ? option
                                    : Array.isArray(option)
                                    ? option.map(o => o?.label)
                                    : option?.label}
                            </li>
                        )}
                        onChange={(e, opts) => handleChange(opts)}
                        onBlur={revealError}
                        onOpen={onOpen}
                        onClose={event => {
                            revealError();
                            onClose?.(event);
                        }}
                        open={open}
                    />
                </FormControl>
            )}
        </FieldValidator>
    );
}
