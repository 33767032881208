import Layout from 'components/Layout/Layout';
import { selectIsAuthenticated } from 'features/auth/auth.slice';
import BundlesPage from 'pages/BundlesPage/BundlesPage';
import CustomerQuotePage from 'pages/CustomerQuotePage/CustomerQuotePage';
import DashboardPage from 'pages/DashboardPage/DashboardPage';
import DealDetailPage from 'pages/DealsPage/DealDetailPage';
import DealQuotePage from 'pages/DealsPage/DealQuotePage';
import DealQuoteProductPage from 'pages/DealsPage/DealQuoteProductPage';
import DealsPage from 'pages/DealsPage/DealsPage';
import DocumentsPage from 'pages/DocumentsPage/DocumentsPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import PurchaseOrderSupplierOrderPage from 'pages/PurchaseOrderSupplierOrderPage/PurchaseOrderSupplierOrderPage';
import PurchaseOrderCreatePage from 'pages/PurchaseOrdersPage/PurchaseOrderCreatePage';
import PurchaseOrderDetailPage from 'pages/PurchaseOrdersPage/PurchaseOrderDetailPage';
import PurchaseOrderProductPage from 'pages/PurchaseOrdersPage/PurchaseOrderProductPage';
import PurchaseOrdersPage from 'pages/PurchaseOrdersPage/PurchaseOrdersPage';
import ResetPasswordPage from 'pages/ResetPasswordPage/ResetPasswordPage';
import SettingsPage from 'pages/SettingsPage/SettingsPage';
import SupplierPage from 'pages/SupplierPage/SupplierPage';
import React, { Suspense, useCallback, useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';

export default function AppRoutes() {
    usePageTitle(window.HOSTED_DOMAIN_NAME || 'Quoterite');

    const isAuthed = useAppSelector(selectIsAuthenticated);

    /** Redirect if the user is already logged in */
    const RequiresUnAuthed = useCallback(() => {
        if (isAuthed) {
            // Important - dont use `useSearchParams` for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            const search = new URLSearchParams(window.location.search);
            const returnUrl = search.get('returnUrl');
            return <Navigate to={returnUrl || '/'} />;
        }
        return <Outlet />;
    }, [isAuthed]);

    /** Redirect if the user is not logged in */
    const RequiresAuthed = useCallback(() => {
        if (isAuthed === false) {
            // if we are def not authed, go to the login
            // Important - dont use the `useLocation` hook for this logic
            // as it will cause the entire Routes stack to re-render whenever the url changes
            let url = window.location.pathname;
            if (window.location.search) {
                url += `${window.location.search}`;
            }
            return <Navigate to={`/login?returnUrl=${encodeURIComponent(url)}`} />;
        }

        // either authed or still checking
        // restore session is handled inside of Layout
        return <Outlet />;
    }, [isAuthed]);

    useEffect(() => {
        document.querySelector('body')?.classList.add('hide-load');
    }, []);

    return (
        <>
            <Suspense fallback={<></>}>
                <Routes>
                    <Route element={<RequiresUnAuthed />}>
                        <Route
                            path="/login"
                            element={<LoginPage />}
                        />
                        <Route
                            path="/reset-password"
                            element={<ResetPasswordPage />}
                        />
                    </Route>
                    <Route element={<RequiresAuthed />}>
                        <Route element={<Layout />}>
                            <Route
                                path="/dashboard"
                                element={<DashboardPage />}
                            />

                            <Route
                                path="/workflows/:workflowId"
                                element={<DealsPage />}
                            >
                                <Route
                                    path=":dealId"
                                    element={<DealDetailPage />}
                                >
                                    <Route
                                        path="quotes/:quoteId"
                                        element={<DealQuotePage />}
                                    >
                                        <Route
                                            path="products/:productId"
                                            element={<DealQuoteProductPage />}
                                        />
                                    </Route>
                                </Route>
                            </Route>
                            <Route
                                path="/orders"
                                element={<PurchaseOrdersPage />}
                            >
                                <Route
                                    path=":orderId"
                                    element={<PurchaseOrderDetailPage />}
                                >
                                    <Route
                                        path="products/:productId"
                                        element={<PurchaseOrderProductPage />}
                                    />
                                    <Route
                                        path="supplier-order/:manufacturerOrderId"
                                        element={<PurchaseOrderSupplierOrderPage />}
                                    />
                                </Route>
                                <Route
                                    path="new"
                                    element={<PurchaseOrderCreatePage />}
                                />
                            </Route>
                            <Route
                                path="/documents"
                                element={<DocumentsPage />}
                            />
                            <Route
                                path="/settings"
                                element={<SettingsPage />}
                            />
                            <Route
                                path="/supplier"
                                element={<SupplierPage />}
                            />
                            <Route
                                path="/bundles"
                                element={<BundlesPage />}
                            />

                            <Route
                                path="*"
                                element={<Navigate to={'/dashboard'} />}
                            />
                        </Route>
                    </Route>
                    {/* Customer portal: public no auth */}
                    <Route
                        path="/customer-quote/:id"
                        element={<CustomerQuotePage />}
                    />
                </Routes>
            </Suspense>
        </>
    );
}
