import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import FrontendFilterStrip, {
    FrontendFilterBuilder,
} from 'components/FrontendFilterStrip/FrontendFilterStrip';
import MyButton from 'components/MyButton/MyButton';
import MySwitchInput from 'components/MySwitchInput/MySwitchInput';
import { DealDetail } from 'features/deals/models/DealDetail';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import QuoteCreateModal from 'features/quotes/components/QuoteCreateModal/QuoteCreateModal';
import QuoteStatusBadge from 'features/quotes/components/QuoteStatusBadge/QuoteStatusBadge';
import QuoteStatus from 'features/quotes/enums/QuoteStatus';
import { Quote } from 'features/quotes/models/Quote';
import quotesApi from 'features/quotes/quotes.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import Icons from 'Icons';
import React, { useMemo, useState } from 'react';
import { ApiTagType } from 'services/api';
import { formatCurrency } from 'utils/helpers';
import './DealQuotesTab.scss';

const COLUMNS = ColumnBuilder<Quote>()
    .column({
        key: 'tuid',
        label: 'Quote',
        isSortable: true,
        getValue: item => item.tuid,
    })
    .column({
        key: 'status',
        label: 'Status',
        isSortable: false,
        getValue: () => QuoteStatus.Draft,
        renderValue: (val, item) => <StatusCell model={item} />,
    })
    .column({
        key: 'products',
        label: 'Products',
        isSortable: false,
        getValue: item => item.context.orderLineCount || null,
        emptyDash: true,
        align: 'center',
    })
    .column({
        key: 'quantity',
        label: 'Total qty',
        isSortable: false,
        getValue: item => item.context.totalQuantity || null,
        emptyDash: true,
        align: 'center',
    })
    .column({
        key: 'total',
        label: 'Total price',
        isSortable: true,
        getValue: item => {
            if (item.context.orderLineCount === 0) {
                return null;
            }
            return item.totalSellPriceOverride ?? item.totalSellPrice;
        },
        renderValue: (value, item) => {
            if (item.totalSellPriceOverride === null) {
                return formatCurrency(value);
            }
            return (
                <>
                    <s>{formatCurrency(item.totalSellPrice)}</s>{' '}
                    {formatCurrency(item.totalSellPriceOverride)}
                </>
            );
        },
        emptyDash: true,
    })
    .build();

export function DealQuotesTab({ model }: { model: DealDetail }) {
    const [showArchived, setShowArchived] = useState(false);
    const filters = useMemo(() => {
        return FrontendFilterBuilder<Quote>()
            .filter({
                label: 'Search',
                type: 'search',
                getFields: (item: Quote) => [item.tuid, item.description],
                defaultValue: '',
            })
            .build();
    }, []);

    const [filteredData, setFilteredData] = useState<Quote[]>();
    const query = quotesApi.useQuoteListQuery({
        dealId: model.id,
        archived: showArchived,
    });

    const [showAddQuoteModal, setShowAddQuoteModal] = React.useState(false);

    const refreshData = useApiTagInvalidate([ApiTagType.Quote]);

    return (
        <>
            {!model.isArchived && (
                <div className="DealQuotesTab__Topbar">
                    <MyButton
                        label="Add quote"
                        IconLeft={Icons.Plus}
                        buttonType="Accent"
                        onClick={() => setShowAddQuoteModal(true)}
                    />
                </div>
            )}

            <FrontendFilterStrip
                filters={filters}
                data={query.data}
                onChange={setFilteredData}
                className="DealQuotesTab__Filters"
                onRefresh={refreshData}
                isRefreshing={query.isFetching}
            >
                <MySwitchInput
                    label="Archived"
                    checked={showArchived}
                    handleInput={setShowArchived}
                />
            </FrontendFilterStrip>

            <DataTable
                className="DealQuotesTab__DataTable"
                columns={COLUMNS}
                isLoading={query.isLoading}
                isRefreshing={query.isFetching}
                isError={query.isError}
                data={filteredData}
                showHeader="narrow"
                zebra="dark"
                rowLinkTo={quote => `quotes/${quote.id}`}
                emptyState="No quotes found"
                scrollable
            />

            {showAddQuoteModal && (
                <OrderPresentation
                    isQuotes={true}
                    listPath={`/workflows/${model.workflowId}/${model.id}`}
                    detailPath={`/workflows/${model.workflowId}/${model.id}/quotes`}
                >
                    <QuoteCreateModal
                        dealId={model.id}
                        close={() => setShowAddQuoteModal(false)}
                    />
                </OrderPresentation>
            )}
        </>
    );
}

function StatusCell({ model }: { model: Quote }) {
    return (
        <div className="DealQuotesTab__StatusCell">
            <QuoteStatusBadge
                status={model.status}
                size="small"
            />
            {model.isArchived && <ArchivedBadge size="small" />}
        </div>
    );
}
