import z from 'zod';
import { DealFlagValueSchema } from './DealFlagValue';

export const DealSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    workflowId: z.string(),
    customerName: z.string(),
    description: z.string(),
    // salesRep: z.string(),
    statusId: z.string(),
    sortOrder: z.string(),
    isArchived: z.boolean(),
    context: z.object({
        quoteCount: z.number().default(0),
        orderCount: z.number().default(0),
        flagValues: z.array(DealFlagValueSchema),
    }),
});

export type Deal = z.infer<typeof DealSchema>;
