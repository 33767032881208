import ErrorContent from 'components/ErrorContent/ErrorContent';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import BundleGroup from 'features/settings/components/BundleGroup/BundleGroup';
import { Bundle } from 'features/settings/models/Bundle';
import settingsApi from 'features/settings/settings.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React, { useMemo } from 'react';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';
import './BundlesPage.scss';

export default function BundlesPage() {
    usePageTitle('Bundles');
    const query = settingsApi.useBundleListQuery();

    type BundleGroup = {
        exclusivityKey: string;
        bundles: Bundle[];
    };

    // Refresh data when the page is loaded
    useApiTagInvalidate([ApiTagType.Bundle], { onMount: true });

    const bundleGroups = useMemo((): BundleGroup[] | undefined => {
        if (!query.data) {
            return undefined;
        }
        const sortedData = [...query.data].sort((a, b) => a.sortOrder - b.sortOrder);
        const result = sortedData.reduce((groups, bundle) => {
            const exclusivityKey = bundle.exclusivityKey ?? '';
            let group = exclusivityKey
                ? groups.find(g => g.exclusivityKey === exclusivityKey)
                : undefined;
            if (!group) {
                group = {
                    exclusivityKey,
                    bundles: [],
                };
                groups.push(group);
            }
            group.bundles.push(bundle);
            return groups;
        }, [] as BundleGroup[]);

        return result;
    }, [query.data]);

    return (
        <>
            <LayoutHeader>
                <PageHeader title="Bundles" />
            </LayoutHeader>
            <LayoutBody
                className="BundlesPage__Container"
                autoPadding
            >
                {query.isLoading ? (
                    <div className="BundlesPage__Container__Loading">
                        <MyLinearProgress />
                    </div>
                ) : query.isError ? (
                    <ErrorContent className="BundlesPage__Container__Error" />
                ) : bundleGroups?.length === 0 ? (
                    <div className="BundlesPage__Container__Empty">No bundles found</div>
                ) : (
                    <>
                        {bundleGroups?.map((group, index) => (
                            <BundleGroup
                                key={`${index} ${group.exclusivityKey}`}
                                title={group.exclusivityKey}
                                bundles={group.bundles}
                            />
                        ))}
                    </>
                )}
            </LayoutBody>
        </>
    );
}
