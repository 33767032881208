import { DateTime } from 'luxon';
import { dateFilterOptionsAny } from 'models/DateFilterOption';
import { isEmpty } from 'utils/helpers';
import { AutocompleteFilterConfig } from '../FilterStrip/Filters/AutocompleteFilter';
import { DateFilterConfig } from '../FilterStrip/Filters/DateFilter';
import { SearchFilterConfig } from '../FilterStrip/Filters/SearchFilter';
import { SelectFilterConfig } from '../FilterStrip/Filters/SelectFilter';
import { TextFilterConfig } from '../FilterStrip/Filters/TextFilter';
import { ToggleFilterConfig } from '../FilterStrip/Filters/ToggleFilter';

/** Default applyFilter function for autocomplete filters */
export function applyAutocompleteFilter<T>(
    config: AutocompleteFilterConfig & { getField: (item: T) => string | undefined | null },
    value: string,
    item: T,
) {
    let val = config.getField(item);
    if (isEmpty(val)) {
        val = '';
    }
    return String(val) === value;
}

/** Default applyFilter function for date filters */
export function applyDateFilter<T>(
    config: DateFilterConfig & { getField: (item: T) => string | undefined | null },
    value: string,
    item: T,
) {
    const minDate = dateFilterOptionsAny.find(d => d.value === value)?.minDate;
    const maxDate = dateFilterOptionsAny.find(d => d.value === value)?.maxDate;

    if (minDate && maxDate) {
        const val = config.getField(item);
        if (!val) {
            return false;
        }
        const date = DateTime.fromISO(val).valueOf();
        return date >= minDate.valueOf() && date <= maxDate.valueOf();
    }
    return true;
}

/** Default applyFilter function for search filters */
export function applySearchFilter<T>(
    config: SearchFilterConfig & { getFields: (item: T) => (string | undefined | null)[] },
    value: string,
    item: T,
) {
    // make sure each token is found on one of the fields
    const tokens = value.trim().split(' ');
    const fields = config.getFields(item);

    // every token must match at least one field
    return tokens.every(token =>
        fields.some(val => {
            return val && val.toLowerCase().indexOf(token.toLowerCase()) > -1;
        }),
    );
}

/** Default applyFilter function for select filters */
export function applySelectFilter<T>(
    config: SelectFilterConfig & { getField: (item: T) => string | undefined | null },
    value: string,
    item: T,
) {
    let val = config.getField(item);
    if (isEmpty(val)) {
        val = '';
    }
    return String(val) === value;
}

/** Default applyFilter function for text filters */
export function applyTextFilter<T>(
    config: TextFilterConfig & { getField: (item: T) => string | undefined | null },
    value: string,
    item: T,
) {
    const val = config.getField(item);
    if (isEmpty(val)) {
        return false;
    }
    return config.exact
        ? String(val).toLowerCase() === value.toLowerCase()
        : String(val).toLowerCase().indexOf(value.toLowerCase()) > -1;
}

/** Default applyFilter function for toggle filters */
export function applyToggleFilter<T>(
    config: ToggleFilterConfig & { getField: (item: T) => string | undefined | null },
    value: string,
    item: T,
) {
    let val = config.getField(item);
    if (isEmpty(val)) {
        val = '';
    }
    return String(val) === value;
}
