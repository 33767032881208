import { CircularProgress } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import React, { forwardRef } from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';
import coalesceClassNames from 'utils/coalesceClassNames';
import './MyButton.scss';

export type ButtonType =
    | 'Primary'
    | 'Secondary'
    | 'Accent'
    | 'Danger'
    | 'Hollow'
    | 'LinkButton'
    | 'Nude'
    | 'None';

type MyButtonProps = {
    isLoading?: boolean;
    disableIfFormInvalid?: boolean;
    label?: React.ReactNode;
    buttonType?: ButtonType;
    IconLeft?: IconType;
    IconRight?: IconType;
} & ButtonProps;

// Using forwardRef to allow tooltips to be displayed on the status pill
// otherwise the tooltip would need an additional wrapper element around this component
const MyButton = forwardRef(
    (
        {
            // manually specify fields that we dont want to pass in to Button
            isLoading = false,
            label = '',
            buttonType = 'Primary',
            IconLeft,
            IconRight,
            ...props
        }: MyButtonProps,
        ref: React.Ref<HTMLButtonElement>,
    ) => {
        let isDisabled = props.disabled;
        if (isLoading) {
            isDisabled = true;
        }
        return (
            <Button
                {...props}
                ref={ref}
                variant="contained"
                disabled={isDisabled}
                className={coalesceClassNames(
                    'MyButton',
                    buttonType && `MyButton--${buttonType}`,
                    props.size && `MyButton--${props.size}`,
                    props.className,
                )}
            >
                <div className="MyButton__Inner">
                    {isLoading ? (
                        <div className="MyButton__Inner__IconLeft Loading">
                            <CircularProgress size="1rem" />
                        </div>
                    ) : IconLeft ? (
                        <IconLeft className="MyButton__Inner__IconLeft" />
                    ) : null}
                    {(props.children || label) && (
                        <div className="MyButton__Inner__Label">{props.children || label}</div>
                    )}
                    {IconRight && <IconRight className="MyButton__Inner__IconRight" />}
                </div>
            </Button>
        );
    },
);

MyButton.displayName = 'MyButton';

export default MyButton;

// eslint-disable-next-line react/display-name
interface MyButtonLinkProps {
    children: React.ReactNode;
    href: string;
}
/** Allows a MyButton to behave as a react-router-dom link
 * usage: `<MyButton href='/route/path' LinkComponent={MyButtonLink} />` */
// eslint-disable-next-line react/display-name
export const MyButtonLink = React.forwardRef(
    (props: MyButtonLinkProps, ref: React.ForwardedRef<any>) => {
        return (
            <Link
                {...props}
                to={props.href}
                ref={ref}
            >
                {props.children}
            </Link>
        );
    },
);

/** Use in conjunction with href property to open urls in a new tab
 * usage: `<MyButton href='/route/path' LinkComponent={MyButtonLinkNewTab} />` */
// eslint-disable-next-line react/display-name
export const MyButtonLinkNewTab = React.forwardRef(
    (props: MyButtonLinkProps, ref: React.ForwardedRef<any>) => {
        return (
            <a
                {...props}
                ref={ref}
                target="_blank"
            >
                {props.children}
            </a>
        );
    },
);
