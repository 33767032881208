import Icons from 'Icons';
import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { FilterCompProps, FilterConfigBase } from '../FilterTypes';

export type SearchFilterConfig = FilterConfigBase & {
    type: 'search';
    size?: 'small' | 'medium';
    autoFocus?: boolean;
};

export default function SearchFilter({ filter, onChange }: FilterCompProps<SearchFilterConfig>) {
    const [value, setValue] = useState(filter.config.defaultValue || '');

    const handleInput = (val: string) => {
        setValue(val);
        filter.value = val;
        onChange?.(filter);
    };

    useEffect(() => {
        setValue(filter.value);
    }, [filter.value]);

    // use config autoFocus attr if it exists,
    // otherwise set up autoFocus for empty non-sticky filters (i.e when a new filter is added)
    const autoFocus = filter.config.autoFocus ?? (!filter.value && !filter.config.isSticky);

    return (
        <div
            className={coalesceClassNames(
                'FilterStrip__Field',
                'FilterStrip__Field--SearchFilter',
                filter.config.className,
            )}
        >
            <MyTextInput
                value={value}
                handleInput={handleInput}
                LeftIcon={Icons.Search}
                showClearButton={true}
                size={(filter as any).size}
                autoFocus={autoFocus}
            />
        </div>
    );
}
