import MySelectInput from 'components/MySelectInput/MySelectInput';

import {
    dateFilterOptionsAny,
    dateFilterOptionsFuture,
    dateFilterOptionsPast,
} from 'models/DateFilterOption';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { FilterCompProps, FilterConfigBase } from '../FilterTypes';

export type DateFilterConfig = FilterConfigBase & {
    type: 'date';
    range?: 'past' | 'future' | 'any';
};

export default function DateFilter({ filter, onChange }: FilterCompProps<DateFilterConfig>) {
    const handleInput = (val: string) => {
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <div
            className={coalesceClassNames(
                'FilterStrip__Field',
                'FilterStrip__Field--DateFilter',
                filter.config.className,
            )}
        >
            <MySelectInput
                label={filter.config.label}
                value={filter.value}
                options={
                    filter.config.range === 'future'
                        ? dateFilterOptionsFuture
                        : filter.config.range === 'past'
                        ? dateFilterOptionsPast
                        : dateFilterOptionsAny
                }
                blankValue="all"
                handleInput={handleInput}
                allowBlank={true}
                defaultOpen={!filter.value && !filter.config.isSticky}
            />
        </div>
    );
}
