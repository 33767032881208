import { z } from 'zod';
import { WorkflowSchema } from './Workflow';
import { WorkflowStatusSchema } from './WorkflowStatus';

export const RelatedDealSchema = z.object({
    id: z.string(),
    tuid: z.string(),
    customerName: z.string(),
    description: z.string(),
    isArchived: z.boolean(),
    context: z
        .object({
            status: WorkflowStatusSchema,
            workflow: WorkflowSchema,
        })
        .optional(),
});

export type RelatedDeal = z.infer<typeof RelatedDealSchema>;
