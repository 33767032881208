import { Tooltip } from '@mui/material';
import { StatusPillSize } from 'components/StatusPill/StatusPill';
import ManufacturerOrderCoreStatus, {
    ManufacturerOrderCoreStatusDisplay,
} from 'features/purchases/enums/ManufacturerOrderCoreStatus';
import { ManufacturerOrderStatus } from 'features/purchases/models/ManufacturerOrderStatus';
import Icons from 'Icons';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './ManufacturerOrderStatusBadge.scss';

export function ManufacturerOrderStatusBadge({
    status,
    size = 'small',
}: {
    status: ManufacturerOrderStatus;
    size?: StatusPillSize;
}) {
    const Icon =
        status.coreStatus === ManufacturerOrderCoreStatus.InProgress
            ? Icons.WorkflowStatusInProgress
            : status.coreStatus === ManufacturerOrderCoreStatus.Completed
            ? Icons.WorkflowStatusClosed
            : status.coreStatus === ManufacturerOrderCoreStatus.Cancelled
            ? Icons.WorkflowStatusCancelled
            : status.coreStatus === ManufacturerOrderCoreStatus.OnHold
            ? Icons.Pause
            : Icons.WorkflowStatusNew;

    return (
        <div
            className={coalesceClassNames(
                'ManufacturerOrderStatusBadge',
                `ManufacturerOrderStatusBadge--${size}`,
                status.coreStatus,
            )}
        >
            <Tooltip
                title={
                    <div className="ManufacturerOrderStatusBadge__Tooltip">
                        {ManufacturerOrderCoreStatusDisplay.display(status.coreStatus)}
                    </div>
                }
                arrow
                placement="top"
                disableInteractive={true}
            >
                <span className="ManufacturerOrderStatusBadge__Icon">
                    <Icon />
                </span>
            </Tooltip>
            <span className="ManufacturerOrderStatusBadge__Label">{status.name}</span>
        </div>
    );
}
