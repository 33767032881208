import { CircularProgress } from '@mui/material';
import Icons from 'Icons';
import MessagePanel from 'components/MessagePanel/MessagePanel';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEditAddress from 'components/PropertyEditAddress/PropertyEditAddress';
import PropertyEditDate from 'components/PropertyEditDate/PropertyEditDate';
import PropertyEditSelect from 'components/PropertyEditSelect/PropertyEditSelect';
import PropertyEditText from 'components/PropertyEditText/PropertyEditText';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import useShippingMethodOptions from 'features/purchases/helpers/useShippingMethodOptions';
import { PurchaseOrderDetail } from 'features/purchases/models/PurchaseOrderDetail';
import purchasesApi from 'features/purchases/purchases.api';
import settingsApi from 'features/settings/settings.api';
import { selectManufacturer } from 'features/settings/settings.slice';
import { DateTime } from 'luxon';
import { useDialogManager } from 'providers/DialogManager';
import FormValidation from 'providers/FormValidation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import coalesceClassNames from 'utils/coalesceClassNames';
import { formatDateRelative } from 'utils/dateHelpers';
import { formatCurrency } from 'utils/helpers';
import './PurchaseOrderReviewModal.scss';

export default function PurchaseOrderReviewModal({
    model,
    close,
}: {
    model: PurchaseOrderDetail;
    close?: () => void;
}) {
    const { entityTitle } = useOrderPresentation();

    const errors = useMemo(() => {
        const result = [] as string[];
        const productErrors = model.context.orderLines.flatMap(line => line.context.errors);
        if (productErrors.length > 0) {
            result.push('One or more products have errors.');
        }
        return result;
    }, [model.context.orderLines]);

    const canSubmit = useMemo(() => {
        if (errors.length > 0) {
            return false;
        }

        if (model.totalQuantity === 0) {
            return false;
        }
        return true;
    }, [errors.length, model.totalQuantity]);

    /** The current step displayed */
    const [step, setStep] = useState<'form' | 'review'>('form');

    const [requiredDate, setRequiredDate] = useState(model.context.minRequiredDate ?? '');

    const manufacturer = useAppSelector(selectManufacturer(model.manufacturerId));
    const isInternalCustomer = manufacturer?.manufacturer_customer_settings?.is_internal;

    // shipping method
    const [shippingMethodId, setShippingMethodId] = useState<number | null>(
        isInternalCustomer
            ? null
            : manufacturer?.customer_settings?.default_shipping_method_id ?? null,
    );
    const shippingMethodOptions = useShippingMethodOptions(manufacturer ?? null);

    // shipping method address
    const shippingMethodAddress = useMemo(() => {
        return isInternalCustomer
            ? null
            : manufacturer?.shipping_methods?.find(method => method.id === shippingMethodId)
                  ?.address;
    }, [isInternalCustomer, manufacturer?.shipping_methods, shippingMethodId]);

    // default shipping instructions
    const dealerSettingsQuery = settingsApi.useDealerSettingsQuery();
    const [shippingInstructions, setShippingInstructions] = useState('');

    useEffect(() => {
        setShippingInstructions(dealerSettingsQuery.data?.defaultShippingInstructions ?? '');
    }, [dealerSettingsQuery.data?.defaultShippingInstructions]);

    // shipping address
    const [shippingAddress, setShippingAddress] = useState('');

    useEffect(() => {
        // ensure correct default shipping address if default shipping address hasn't been loaded
        setShippingAddress(dealerSettingsQuery.data?.companyDeliveryAddress ?? '');
    }, [dealerSettingsQuery.data?.companyDeliveryAddress]);

    // required date
    const dtRequiredDate = useMemo(() => {
        if (!requiredDate) {
            return undefined;
        }
        const dt = requiredDate ? DateTime.fromISO(requiredDate) : undefined;
        return dt;
    }, [requiredDate]);

    const customError = useMemo(() => {
        if (dtRequiredDate && !dtRequiredDate.isValid) {
            return 'Invalid date';
        }
        // check if required date is before min required date
        // if min required date is not set, default to tomorrow
        const dt = DateTime.fromISO(model.context.minRequiredDate ?? '');
        const min = dt.isValid ? dt : DateTime.now().startOf('day').plus({ days: 1 });
        if ((dtRequiredDate?.diff(min).as('milliseconds') ?? 0) < 0) {
            return `Earliest allowed date is ${formatDateRelative(min, { alwaysDate: true })}`;
        }

        return undefined;
    }, [dtRequiredDate, model.context.minRequiredDate]);

    const [freightEstimateQuery, freightEstimateQueryStatus] =
        ordersApi.useLazyOrderFreightEstimateQuery();
    const [freightEstimate, setFreightEstimate] = useState<number>();

    const recalculateFreight = useCallback(
        async (props: { orderId: number; shippingMethodId: number; manufacturerId: number }) => {
            const result = await freightEstimateQuery(props).unwrap();
            setFreightEstimate(result);
        },
        [freightEstimateQuery],
    );

    useEffect(() => {
        if (!isInternalCustomer && shippingMethodId && manufacturer?.id) {
            recalculateFreight({
                orderId: model.id,
                shippingMethodId,
                manufacturerId: manufacturer?.id,
            });
        } else {
            setFreightEstimate(undefined);
        }
    }, [isInternalCustomer, manufacturer?.id, model.id, recalculateFreight, shippingMethodId]);

    const [pdfUrl, setPdfUrl] = useState('');

    return (
        <MyModal
            className={coalesceClassNames(
                'PurchaseOrderReviewModal',
                `PurchaseOrderReviewModal--${step}`,
            )}
            close={close}
            mobileTitle={entityTitle}
            fullHeight
            isLoading={dealerSettingsQuery.isLoading}
            header={
                <>
                    <PageHeader
                        className="PurchaseOrderReviewModal__PageHeader"
                        title="Review Order"
                        titleContext={model?.tuid}
                    >
                        {step === 'review' && (
                            <MyButton
                                label="Download PDF"
                                buttonType="Hollow"
                                IconLeft={Icons.Download}
                                href={pdfUrl}
                                LinkComponent={MyButtonLinkNewTab}
                            />
                        )}
                    </PageHeader>

                    <div className="PurchaseOrderReviewModal__Header__Error">
                        {!canSubmit && errors.length > 0 && (
                            <MessagePanel messageType="error">
                                <p>Please resolve the following issues before submitting</p>
                                <ul>
                                    {errors.map((err, i) => (
                                        <li key={i}>{err}</li>
                                    ))}
                                </ul>
                            </MessagePanel>
                        )}
                    </div>
                </>
            }
        >
            {step === 'form' ? (
                <div className="PurchaseOrderReviewModal__FormStep">
                    <FormValidation submit={() => setStep('review')}>
                        {({ handleSubmit }) => (
                            <>
                                <PropertyContainer className="PurchaseOrderReviewModal__FormStep__Container">
                                    <PropertyEditDate
                                        label="Required date"
                                        hint={
                                            <>
                                                Based on the products in your order, the earliest
                                                date allowed is{' '}
                                                <strong>
                                                    {formatDateRelative(
                                                        model.context.minRequiredDate ?? '',
                                                        {
                                                            alwaysDate: true,
                                                        },
                                                    )}
                                                </strong>
                                            </>
                                        }
                                        min={model.context.minRequiredDate ?? ''}
                                        value={requiredDate}
                                        onChange={val => setRequiredDate(val ?? '')}
                                        validationRequired={'Please specify the required date'}
                                        validationCustom={customError}
                                    />
                                    {!isInternalCustomer && (
                                        <PropertyEditSelect
                                            label="Shipping method"
                                            value={`${shippingMethodId ?? ''}`}
                                            options={shippingMethodOptions}
                                            onChange={val => setShippingMethodId(parseInt(val, 10))}
                                            validationRequired
                                            fullWidth
                                        />
                                    )}
                                    {!isInternalCustomer &&
                                        (shippingMethodAddress ? (
                                            <div className="PurchaseOrderReviewModal__StaticAddressDisplay">
                                                <Icons.Location className="icon" />
                                                {shippingMethodAddress}
                                            </div>
                                        ) : (
                                            <PropertyEditAddress
                                                label="Shipping address"
                                                value={shippingAddress}
                                                onChange={setShippingAddress}
                                                validationRequired
                                            />
                                        ))}

                                    <PropertyEditText
                                        label="Shipping instructions"
                                        value={shippingInstructions}
                                        onChange={val => setShippingInstructions(val ?? '')}
                                        multiline
                                    />
                                    {!isInternalCustomer && (
                                        <PropertyDisplay
                                            label="Estimated freight"
                                            value={
                                                freightEstimateQueryStatus.isFetching ? (
                                                    <CircularProgress size={24} />
                                                ) : (
                                                    formatCurrency(freightEstimate)
                                                )
                                            }
                                        />
                                    )}
                                </PropertyContainer>

                                <div className="PurchaseOrderReviewModal__FormStep__Footer">
                                    <MyButton
                                        label="Cancel"
                                        buttonType="Hollow"
                                        onClick={close}
                                    />
                                    <MyButton
                                        label="Next"
                                        onClick={handleSubmit}
                                    />
                                </div>
                            </>
                        )}
                    </FormValidation>
                </div>
            ) : (
                <ReviewStep
                    model={model}
                    requiredDate={requiredDate}
                    manufacturerId={manufacturer?.id ?? 0}
                    isInternalCustomer={isInternalCustomer ?? false}
                    shippingAddress={shippingMethodAddress || shippingAddress}
                    shippingInstructions={shippingInstructions}
                    shippingMethodId={isInternalCustomer ? null : shippingMethodId ?? 0}
                    pdfUrl={pdfUrl}
                    canSubmit={canSubmit}
                    goBack={() => setStep('form')}
                    close={close}
                    setPdfUrl={setPdfUrl}
                />
            )}
        </MyModal>
    );
}

function ReviewStep({
    model,
    manufacturerId,
    isInternalCustomer,
    requiredDate,
    shippingMethodId,
    shippingAddress,
    shippingInstructions,
    pdfUrl,
    canSubmit,
    goBack,
    close,
    setPdfUrl,
}: {
    model: PurchaseOrderDetail;
    manufacturerId: number;
    isInternalCustomer: boolean;
    requiredDate: string;
    shippingMethodId: number | null;
    shippingAddress: string | null;
    shippingInstructions: string;
    pdfUrl: string;
    canSubmit: boolean;
    goBack: () => void;
    close?: () => void;
    setPdfUrl: (url: string) => void;
}) {
    const dialogManager = useDialogManager();
    const [submitMutation] = purchasesApi.usePurchaseOrderSubmitMutation();

    // PDF generation
    const [generatePdfQuery] = purchasesApi.useLazyGeneratePdfQuery();

    const generatePdf = useCallback(async () => {
        const url = await generatePdfQuery({
            orderId: model.id,
            manufacturerId,
            requiredDate,
            shippingMethodId: isInternalCustomer ? null : shippingMethodId,
            shippingAddress: isInternalCustomer ? null : shippingAddress,
            shippingInstructions: isInternalCustomer ? null : shippingInstructions,
        }).unwrap();
        setPdfUrl(url);
    }, [
        generatePdfQuery,
        model.id,
        manufacturerId,
        requiredDate,
        isInternalCustomer,
        shippingMethodId,
        shippingAddress,
        shippingInstructions,
        setPdfUrl,
    ]);

    useEffect(() => {
        generatePdf();
    }, [generatePdf]);

    const submit = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: 'Submit order',
            message: 'Orders cannot be edited after submission. Are you sure?',
            acceptLabel: 'Yes, submit order',
            acceptButtonType: 'Accent',
        });
        if (confirm) {
            await dialogManager.showLoadingWhile(
                submitMutation({
                    orderId: model.id,
                    requiredDate,
                    shippingAddress,
                    shippingInstructions,
                    shippingMethodId,
                }).unwrap(),
            );
            close?.();
        }
    }, [
        close,
        dialogManager,
        model.id,
        requiredDate,
        shippingAddress,
        shippingInstructions,
        shippingMethodId,
        submitMutation,
    ]);

    const [isIframeLoading, setIsIframeLoading] = useState(true);

    return (
        <div className="PurchaseOrderReviewModal__ReviewStep">
            {isIframeLoading && (
                <div className="PurchaseOrderReviewModal__ReviewStep__Loading">
                    <MyLinearProgress delay={0} />
                </div>
            )}

            <iframe
                className="PurchaseOrderReviewModal__ReviewStep__ContentFrame"
                src={pdfUrl ? `${pdfUrl}#toolbar=0` : undefined}
                onLoad={() => setIsIframeLoading(false)}
            />

            <div className="PurchaseOrderReviewModal__ReviewStep__Footer">
                <MyButton
                    label="Back"
                    buttonType="Nude"
                    IconLeft={Icons.ChevronLeft}
                    onClick={goBack}
                />
                <div className="PurchaseOrderReviewModal__ReviewStep__Footer__Buttons">
                    <MyButton
                        label="Submit order"
                        buttonType="Accent"
                        onClick={submit}
                        disabled={!canSubmit}
                    />
                </div>
            </div>
        </div>
    );
}
