import MyTextInput from 'components/MyTextInput/MyTextInput';
import React, { useEffect, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { FilterCompProps, FilterConfigBase } from '../FilterTypes';

export type TextFilterConfig = FilterConfigBase & {
    type: 'text';
    exact?: boolean;
};

export default function TextFilter({ filter, onChange }: FilterCompProps<TextFilterConfig>) {
    const [value, setValue] = useState(filter.config.defaultValue || '');

    const handleInput = (val: string) => {
        setValue(val);
        filter.value = val;
        onChange?.(filter);
    };

    useEffect(() => {
        setValue(filter.value);
    }, [filter.value]);

    return (
        <div
            className={coalesceClassNames(
                'FilterStrip__Field',
                'FilterStrip__Field--TextFilter',
                filter.config.className,
            )}
        >
            <MyTextInput
                label={filter.config.label}
                value={value}
                handleInput={handleInput}
                showClearButton={true}
                autoFocus={!filter.value && !filter.config.isSticky}
            />
        </div>
    );
}
