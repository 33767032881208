import Icons from 'Icons';
import MySwitchInput from 'components/MySwitchInput/MySwitchInput';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import PropertyEdit from 'components/PropertyEdit/PropertyEdit';
import React, { useRef } from 'react';
import { generateShortUuid } from 'utils/helpers';
import './PropertyEditSwitch.scss';

export type PropertyEditSwitchProps = {
    checked?: boolean;
    label: string;
    hint?: React.ReactNode;
    inlineHint?: React.ReactNode;
    onChange?: (val: boolean) => void;
    readonly?: boolean;
    disabled?: boolean;
    validationRequired?: string | boolean;
    validationCustom?: string | false;
};

export default function PropertyEditSwitch({
    checked,
    label,
    hint,
    inlineHint,
    onChange,
    readonly = false,
    disabled = false,
    validationRequired,
    validationCustom,
}: PropertyEditSwitchProps) {
    const inputId = useRef(generateShortUuid()).current;

    const validationRequiredWithLabel =
        validationRequired === true ? `${label} is required` : validationRequired;

    if (readonly) {
        return (
            <PropertyDisplay
                label={label}
                hint={hint}
                inlineHint={inlineHint}
                value={
                    checked === true ? (
                        <Icons.Check className="PropertyEditSwitch__DisplayCheck" />
                    ) : (
                        ''
                    )
                }
            />
        );
    }
    return (
        <PropertyEdit
            className="PropertyEditSwitch"
            label={label}
            hint={hint}
            inlineHint={inlineHint}
            inputId={inputId}
        >
            <MySwitchInput
                className="PropertyEditSwitch__Input"
                id={inputId}
                checked={checked}
                handleInput={onChange}
                disabled={disabled}
                validationRequired={validationRequiredWithLabel}
                validationCustom={validationCustom}
            />
        </PropertyEdit>
    );
}
