import MySwitchInput from 'components/MySwitchInput/MySwitchInput';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { FilterCompProps, FilterConfigBase } from '../FilterTypes';

export type ToggleFilterConfig = FilterConfigBase & {
    type: 'toggle';
    allowBlank?: boolean;
    trueValue?: string;
    falseValue?: string;
};

export default function ToggleFilter({ filter, onChange }: FilterCompProps<ToggleFilterConfig>) {
    const trueValue = filter.config.trueValue ?? 'true';
    const falseValue = filter.config.falseValue ?? 'false';
    const handleCheckedChanged = (checked: boolean) => {
        const val = checked ? trueValue : falseValue;
        filter.value = val;
        onChange?.(filter);
    };

    return (
        <MySwitchInput
            className={coalesceClassNames(
                'FilterStrip__Field',
                'FilterStrip__Field--ToggleFilter',
                filter.config.className,
            )}
            label={filter.config.label}
            checked={filter.value === trueValue}
            handleInput={handleCheckedChanged}
        />
    );
}
