import Icons from 'Icons';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyButton, { MyButtonLink } from 'components/MyButton/MyButton';
import PageHeader from 'components/PageHeader/PageHeader';
import OrderPresentation from 'features/orders/providers/OrderPresentation';
import PurchaseOrdersTable from 'features/purchases/components/PurchaseOrdersTable/PurchaseOrdersTable';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';

export default function PurchaseOrdersPage() {
    usePageTitle('Orders');

    // Refresh data when the page is loaded
    useApiTagInvalidate([ApiTagType.PurchaseOrder], { onMount: true });

    return (
        <OrderPresentation
            isQuotes={false}
            listPath="/orders"
            detailPath="/orders"
        >
            <LayoutHeader>
                <PageHeader title="Orders">
                    <MyButton
                        label="New order"
                        IconLeft={Icons.Plus}
                        buttonType="Accent"
                        href="new"
                        LinkComponent={MyButtonLink}
                    />
                </PageHeader>
            </LayoutHeader>
            <PurchaseOrdersTable />

            <Outlet />
        </OrderPresentation>
    );
}
