import { DateTime } from 'luxon';
import { z } from 'zod';
import { fixDateStringToIso } from './dateHelpers';

/** A date string in the format YYYY-MM-dd
 * If allowBlank is true, then empty string is also allowed
 */
export const dateOnly = (opts?: { allowBlank?: boolean }) =>
    z.string().refine(
        val => {
            return (
                (val === '' && opts?.allowBlank) ||
                (val.match(/^\d{4}-\d{2}-\d{2}$/) && DateTime.fromISO(val).isValid)
            );
        },
        val => ({
            message: `Value '${val}' is not a valid dateOnly format`,
        }),
    );

/** A dateTime string inISO format including timezone offset.
 * This represents a specific moment in time
 * If allowBlank is true, then empty string is also allowed
 */
export const dateTimeInstant = (opts?: { allowBlank?: boolean }) =>
    z.string().refine(
        val => {
            return (
                (val === '' && opts?.allowBlank) ||
                (DateTime.fromISO(val).isValid && hasOffset(val))
            );
        },
        val => ({
            message: `Value '${val}' is not a valid dateTimeInstant format`,
        }),
    );

/** A dateTime string inISO format but without any timezone offset.
 * This represents wall clock time without any timezone information.
 * If allowBlank is true, then empty string is also allowed
 */
export const dateTimeLocal = (opts?: { allowBlank?: boolean }) =>
    z.string().refine(
        val => {
            return (
                (val === '' && opts?.allowBlank) ||
                (DateTime.fromISO(val).isValid && !hasOffset(val))
            );
        },
        val => ({
            message: `Value '${val}' is not a valid dateTimeLocal format`,
        }),
    );

/** A dateTime string in non-ISO format and without any timezone offset.
 * This is generally how dates come back from the old API
 */
export const dateTimeOldSystem = (opts?: { allowBlank?: boolean; isUTC?: boolean }) =>
    z
        .string()
        .refine(
            val => {
                return (
                    val === '' ||
                    (val === '0000-00-00 00:00:00' && opts?.allowBlank) ||
                    (DateTime.fromFormat(val, 'yyyy-MM-dd HH:mm:ss').isValid && !hasOffset(val))
                );
            },
            val => ({
                message: `Value '${val}' is not a valid dateTimeLocal format`,
            }),
        )
        .transform(val => {
            if (val === '0000-00-00 00:00:00') {
                return '';
            }
            return fixDateStringToIso(
                val,
                'yyyy-MM-dd HH:mm:ss',
                opts?.isUTC ? 'UTC' : window.COMPANY_TIMEZONE,
            );
        });

// helper functions to determine if a string includes offset or not
// https://stackoverflow.com/questions/71311085/how-to-check-if-the-data-time-has-the-timezone-offset-or-not

const isoDatePattern = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})(\.\d+)?([+-]\d{2}:?\d{2}|Z)$/;

function getOffsetStr(input: string) {
    const groups = input.match(isoDatePattern);
    return groups ? groups[4] : null;
}

export function hasOffset(input: string) {
    return !!getOffsetStr(input);
}

/** Any value type that can be rendered easily - allows string, number or boolean */
export function valueType() {
    return z.union([z.string(), z.number(), z.boolean()]);
}
