import { PendingUpdatesSchema } from 'models/PendingUpdates';
import { dateTimeInstant } from 'utils/customSchemas';
import z from 'zod';
import QuoteStatus from '../enums/QuoteStatus';
import { QuoteOrderLineSchema } from './QuoteOrderLine';

export const QuoteDetailSchema = z
    .object({
        id: z.string(),
        externalId: z.number({ coerce: true }),
        tuid: z.string(),
        createdAt: dateTimeInstant(),
        description: z.string(),
        status: z.nativeEnum(QuoteStatus),
        isArchived: z.boolean(),
        isReadOnly: z.boolean().default(false),
        totalSellPrice: z.number(),
        totalSellPriceOverride: z.number().nullable(),
        context: z.object({
            totalQuantity: z.number(),
            orderLineCount: z.number(),
            orderLines: z.array(QuoteOrderLineSchema),
            deal: z.object({
                id: z.string(),
                tuid: z.string(),
                workflowId: z.string(),
                customerName: z.string(),
                description: z.string(),
                statusId: z.string(),
                sortOrder: z.string(),
                isArchived: z.boolean().optional(),
            }),
            workflow: z.object({
                id: z.string(),
                name: z.string(),
            }),
            pendingUpdates: PendingUpdatesSchema.nullable(),
        }),
    })
    .transform(model => ({
        ...model,
        isQuote: true as const,
        isPurchase: false as const,
        isReadOnly: model.status === QuoteStatus.Accepted || model.isArchived,
    }));

export type QuoteDetail = z.infer<typeof QuoteDetailSchema>;
