import { dateTimeInstant } from 'utils/customSchemas';
import z from 'zod';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';
import { ManufacturerOrderStatusSchema } from './ManufacturerOrderStatus';

export const PurchaseOrderSchema = z
    .object({
        id: z.number(),
        tuid: z.string(),
        status: z.nativeEnum(PurchaseOrderStatus),
        sidemark: z.string(),
        createdAt: dateTimeInstant(),
        isArchived: z.boolean(),
        salesRep: z.string(),
        customerDetails: z.string(),
        totalCost: z.number(),
        totalQuantity: z.number(),
        manufacturerId: z.number(),
        context: z.object({
            manufacturerOrderStatuses: z.array(ManufacturerOrderStatusSchema).optional(),
        }),
    })
    .transform(model => {
        return {
            ...model,
            isPurchase: true as const,
            isQuote: false as const,
        };
    });

export type PurchaseOrder = z.infer<typeof PurchaseOrderSchema>;
