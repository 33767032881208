import { ApiTagType, api } from 'services/api';
import { DashboardData, DashboardDataSchema } from './models/DashboardData';

export const dashboardApi = api.injectEndpoints({
    endpoints: build => ({
        dashboardData: build.query<DashboardData, void>({
            query: () => ({
                url: `/dashboard`,
                method: 'GET',
            }),
            transformResponse: (result: unknown) => DashboardDataSchema.parse(result),
            providesTags: [
                ApiTagType.Dashboard,
                ApiTagType.PurchaseOrder,
                ApiTagType.Deal,
                ApiTagType.Quote,
            ],
        }),
    }),
});

export default dashboardApi;
