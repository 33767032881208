import { dateOnly, dateTimeOldSystem, valueType } from 'utils/customSchemas';
import { tryParseJson } from 'utils/helpers';
import z from 'zod';

const ManufacturerOrderHistoryStateSchema = z.object({
    customer_notes: z.string().nullable().optional(),
    eta: dateOnly().nullable().optional(),
    id: z.number(),
    order_status_id: valueType().nullable().optional(),
    total_freight_override: valueType().nullable().optional(),
    tracking_information: z.string().nullable().optional(),
});

export type HistoryState = z.infer<typeof ManufacturerOrderHistoryStateSchema>;

export const ManufacturerOrderHistoryEntrySchema = z
    .object({
        id: z.number(),
        account_id: z.number(),
        contact_id: z.number().nullable(),
        contacts_first_name: z.string().nullable(),
        contacts_last_name: z.string().nullable(),
        // currently the backend is showing the dateTime in manufacturer TimeZone
        // which will be fixed in the future
        created_at: dateTimeOldSystem({ isUTC: true }),
        created_by: z.number(),
        deleted_at: z.string().nullable(),
        documents: z.array(z.any()),
        employees_first_name: z.string(),
        employees_last_name: z.string(),
        event: z.string(),
        event_item: z.any().nullable(),
        logs: z.string(),
        manufacturer_employee_id: z.number(),
        manufacturer_employees_first_name: z.string(),
        manufacturer_employees_last_name: z.string(),
        model: z.string(),
        model_id: z.number(),
        new: z.string(),
        old: z.string(),
        secondary_model: z.any().nullable(),
        secondary_model_id: z.number().nullable(),
        type: z.string(),
        updated_at: dateTimeOldSystem({ isUTC: true }),
        updated_by: z.number().nullable(),
    })
    .transform(model => {
        const activityNew = ManufacturerOrderHistoryStateSchema.parse(tryParseJson(model.new));
        const activityOld = ManufacturerOrderHistoryStateSchema.parse(tryParseJson(model.old));
        return {
            ...model,
            manufacturer_employees_full_name: `${model.manufacturer_employees_first_name} ${model.manufacturer_employees_last_name}`,
            new: activityNew,
            old: activityOld,
        };
    });

export type ManufacturerOrderHistoryEntry = z.infer<typeof ManufacturerOrderHistoryEntrySchema>;
