import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './SectionHeader.scss';

export default function SectionHeader({
    className,
    title,
    titleContext,
    children,
    autoMargin,
}: {
    className?: string;
    title: React.ReactNode;
    titleContext?: React.ReactNode;
    children?: React.ReactFragment;
    autoMargin?: boolean;
}) {
    return (
        <div
            className={coalesceClassNames(
                'SectionHeader',
                autoMargin && 'SectionHeader--autoMargin',
                className,
            )}
        >
            <div className="SectionHeader__TitleContainer">
                <h2 className="SectionHeader__Title">{title}</h2>
                {titleContext && <div className="SectionHeader__TitleContext">{titleContext}</div>}
            </div>

            {children && <div className="SectionHeader__Content">{children}</div>}
        </div>
    );
}
