import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import ErrorContent from 'components/ErrorContent/ErrorContent';
import FrontendFilterStrip, {
    FrontendFilterBuilder,
} from 'components/FrontendFilterStrip/FrontendFilterStrip';
import { useLayoutClassModifier } from 'components/Layout/Layout';
import LayoutBody from 'components/LayoutBody/LayoutBody';
import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import PageHeader from 'components/PageHeader/PageHeader';
import DealCreateModal from 'features/deals/components/DealCreateModal/DealCreateModal';
import DealsKanban from 'features/deals/components/DealsKanban/DealsKanban';
import DealsTable from 'features/deals/components/DealsTable/DealsTable';
import dealsApi from 'features/deals/deals.api';
import {
    DealsListViewType,
    selectDealsListViewType,
    setDealsListViewType,
} from 'features/deals/deals.slice';
import { Deal } from 'features/deals/models/Deal';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import useUrlQueryState from 'hooks/useUrlQueryState';
import Icons from 'Icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { ApiTagType } from 'services/api';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { usePageTitle } from 'utils/locationHelpers';
import './DealsPage.scss';

export default function DealsPage() {
    const { workflowId } = useParams() as {
        workflowId: string;
    };

    if (!workflowId) {
        return null;
    }

    return (
        <DealsPagePure
            key={workflowId} // key prevents bleed when switching between workflows
            workflowId={workflowId}
        />
    );
}

function DealsPagePure({ workflowId }: { workflowId: string }) {
    // Workflow list should already be loaded when app starts
    // so we can use that to get name instantly to use for page title + header
    const workflowListQuery = dealsApi.useWorkflowListQuery();
    const workflowSummary = workflowListQuery.data?.find(w => w.id === workflowId);

    usePageTitle(workflowSummary?.name);

    // Refresh data when the page is loaded
    const refreshData = useApiTagInvalidate([ApiTagType.Deal], { onMount: true });

    const workflowDetailQuery = dealsApi.useWorkflowDetailQuery(workflowId);
    const workflowDetail = workflowDetailQuery.data;

    const query = dealsApi.useWorkflowDealListQuery(workflowId);

    const { filteredData, setFilteredData, filteredStatusId, filters } = useFilters(workflowDetail);

    const navigate = useNavigate();

    useEffect(() => {
        if (!workflowSummary && !workflowListQuery.isFetching) {
            navigate(`/`);
        }
    });

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

    const viewType = useAppSelector(selectDealsListViewType);
    const dispatch = useAppDispatch();

    const setViewType = useCallback(
        (val: DealsListViewType) => {
            if (val !== viewType) {
                dispatch(setDealsListViewType(val));
            }
        },
        [dispatch, viewType],
    );

    useLayoutClassModifier(viewType === 'kanban' ? 'DealsPage--kanban' : 'DealsPage--table');

    if (!workflowSummary) {
        return null;
    }

    return (
        <>
            <LayoutHeader>
                <PageHeader title={workflowSummary?.name}>
                    <MyButton
                        onClick={() => setIsCreateModalOpen(true)}
                        label={`Add`}
                        IconLeft={Icons.Plus}
                        buttonType="Accent"
                    />
                    <ToggleButtonGroup
                        className="DealsPage__DisplayToggle"
                        value={viewType}
                        exclusive
                        onChange={(_, val) => {
                            setViewType(val ?? 'kanban');
                        }}
                    >
                        <ToggleButton value="kanban">
                            <Tooltip
                                title="Kanban view"
                                placement="bottom"
                                arrow
                            >
                                <div className="DealsPage__DisplayToggle__IconWrapper">
                                    <Icons.KanbanView size={20} />
                                </div>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="table">
                            <Tooltip
                                title="Table view"
                                placement="bottom"
                                arrow
                            >
                                <div className="DealsPage__DisplayToggle__IconWrapper">
                                    <Icons.TableView size={20} />
                                </div>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </PageHeader>
            </LayoutHeader>

            {workflowDetailQuery.isLoading ? (
                <MyLinearProgress />
            ) : workflowDetailQuery.isError ? (
                <ErrorContent />
            ) : (
                workflowDetail && (
                    <>
                        <LayoutHeader>
                            <FrontendFilterStrip
                                className="DealsPage__Filters"
                                data={query.data}
                                filters={filters}
                                onChange={setFilteredData}
                                onRefresh={refreshData}
                                isRefreshing={query.isFetching}
                            />
                        </LayoutHeader>

                        {viewType === 'table' ? (
                            <LayoutBody className="DealsPage__LayoutBody--table">
                                <DealsTable
                                    workflow={workflowDetail}
                                    data={filteredData}
                                    isLoading={query.isLoading}
                                    isError={query.isError}
                                    isFetching={query.isFetching}
                                />
                            </LayoutBody>
                        ) : (
                            <LayoutBody
                                className="DealsPage__LayoutBody--kanban"
                                autoPadding
                            >
                                <DealsKanban
                                    workflow={workflowDetail}
                                    data={filteredData}
                                    filteredStatusId={filteredStatusId}
                                    isLoading={query.isLoading}
                                    isError={query.isError}
                                    isFetching={query.isFetching}
                                />
                            </LayoutBody>
                        )}

                        {isCreateModalOpen && (
                            <DealCreateModal
                                workflow={workflowDetail}
                                close={() => setIsCreateModalOpen(false)}
                            />
                        )}
                        <Outlet />
                    </>
                )
            )}
        </>
    );
}

function useFilters(workflowDetail?: WorkflowDetail) {
    const [filteredData, setFilteredData] = useState<Deal[]>();

    const [filteredStatusId] = useUrlQueryState('status');

    const filters = useMemo(() => {
        const filterBuilder = FrontendFilterBuilder<Deal>()
            .filter({
                type: 'search',
                label: 'Search',
                urlParam: 'search',
                getFields: item => [item.customerName, item.description],
            })
            .filter({
                label: 'Status',
                type: 'select',
                urlParam: 'status',
                options: workflowDetail?.context.statuses.map(status => ({
                    value: status.id,
                    label: status.name,
                })),
                getField: deal => deal.statusId,
            });
        workflowDetail?.context.flags.forEach(flag => {
            filterBuilder.filter({
                label: flag.name,
                type: 'select',
                urlParam: `_${flag.name}`,
                isSticky: false,
                options: flag.values.map(value => ({
                    value: value.id,
                    label: value.label,
                })),
                getField: deal =>
                    deal.context.flagValues.find(fv => fv.workflowFlagId === flag.id)
                        ?.workflowFlagValueId,
            });
        });
        return filterBuilder.build();
    }, [workflowDetail]);

    return { filteredData, setFilteredData, filters, filteredStatusId };
}
