import { PendingUpdatesSchema } from 'models/PendingUpdates';
import { dateOnly, dateTimeInstant } from 'utils/customSchemas';
import z from 'zod';
import PurchaseOrderStatus from '../enums/PurchaseOrderStatus';
import { PurchaseOrderLineSchema } from './PurchaseOrderLine';

export const PurchaseOrderDetailSchema = z
    .object({
        id: z.number(), // this is the legacy id for now
        tuid: z.string(),
        manufacturerId: z.number(),
        sidemark: z.string().nullable(),
        createdAt: dateTimeInstant(),
        isArchived: z.boolean(),
        salesRep: z.string(),
        customerDetails: z.string().nullable(),
        notes: z.string(),
        status: z.nativeEnum(PurchaseOrderStatus),
        totalCost: z.number(),
        totalQuantity: z.number(),
        context: z.object({
            minRequiredDate: dateOnly().nullable().optional(),
            orderDocument: z
                .object({
                    id: z.number(),
                    url: z.string(),
                })
                .nullable(),
            parentOrder: z
                .object({
                    id: z.number(),
                    tuid: z.string().optional(),
                    isQuote: z.boolean().optional(),
                })
                .nullable(),
            latestRevision: z
                .object({
                    id: z.number(),
                    tuid: z.string(),
                })
                .nullable(),
            pendingUpdates: PendingUpdatesSchema.nullable(),
            orderLines: z.array(PurchaseOrderLineSchema),
        }),
    })
    .transform(model => {
        return {
            ...model,
            isPurchase: true as const,
            isQuote: false as const,
            isReadOnly: model.status === PurchaseOrderStatus.Submitted || model.isArchived,
        };
    });

export type PurchaseOrderDetail = z.infer<typeof PurchaseOrderDetailSchema>;
