import { Tooltip } from '@mui/material';
import Icons from 'Icons';
import ArchivedBadge from 'components/ArchivedBadge/ArchivedBadge';
import MyButton, { MyButtonLinkNewTab } from 'components/MyButton/MyButton';
import { MyMenuButtonItem } from 'components/MyMenuButton/MyMenuButton';
import MyMenuKebabButton from 'components/MyMenuKebabButton/MyMenuKebabButton';
import MyModal from 'components/MyModal/MyModal';
import PageHeader from 'components/PageHeader/PageHeader';
import PropertyContainer from 'components/PropertyContainer/PropertyContainer';
import PropertyDisplay from 'components/PropertyDisplay/PropertyDisplay';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import OrderUpdatesMessagePanel from 'features/orders/components/OrderUpdatesMessagePanel/OrderUpdatesMessagePanel';
import QuoteStatus from 'features/quotes/enums/QuoteStatus';
import { QuoteDetail } from 'features/quotes/models/QuoteDetail';
import quotesApi from 'features/quotes/quotes.api';
import { useDialogManager } from 'providers/DialogManager';
import React, { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils/helpers';
import OrderCloneModal from '../QuoteCloneModal/QuoteCloneModal';
import QuoteEditModal from '../QuoteEditModal/QuoteEditModal';
import QuotePriceEditModal from '../QuotePriceEditModal/QuotePriceEditModal';
import QuotePdfModal from '../QuoteReviewModal/QuotePdfModal';
import QuoteStatusBadge from '../QuoteStatusBadge/QuoteStatusBadge';
import './QuoteDetailModal.scss';
import QuoteProductList from './QuoteProductList';

export default function QuoteDetailModal({
    model,
    isLoading,
    isError,
    close,
}: {
    model?: QuoteDetail;
    isLoading?: boolean;
    isError?: boolean;
    close?: () => void;
}) {
    const [archiveMutation] = quotesApi.useQuoteArchiveMutation();
    const [unarchiveMutation] = quotesApi.useQuoteUnarchiveMutation();
    const [acceptMutation] = quotesApi.useQuoteAcceptMutation();

    const dialogManager = useDialogManager();

    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showPdfModal, setShowPdfModal] = useState(false);
    const [showPriceEditModal, setShowPriceEditModal] = useState(false);

    const hasErrors = model?.context.orderLines.some(line => line.context.errors.length > 0);

    const archive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Archive quote`,
            message: `Are you sure you want to archive this quote?`,
            acceptLabel: 'Yes, archive now',
            acceptButtonType: 'Danger',
        });

        if (confirm && model) {
            const result = await dialogManager.showLoadingWhile(archiveMutation(model.id));
            if (!(result as any).error) {
                // close if successful
                close?.();
            }
        }
    }, [archiveMutation, close, dialogManager, model]);

    const unarchive = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `unarchive quote`,
            message: `Are you sure you want to unarchive this quote?`,
            acceptLabel: 'Yes, unarchive now',
            acceptButtonType: 'Primary',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(unarchiveMutation(model.id));
        }
    }, [unarchiveMutation, dialogManager, model]);

    const accept = useCallback(async () => {
        const confirm = await dialogManager.confirm({
            title: `Accept Quote`,
            message: (
                <>
                    <p>
                        Once accepted this quote will be locked and ordering can begin. However you
                        will not be able to make any further changes to the products or pricing.
                    </p>
                    <p>Are you sure you want to mark this quote as accepted?</p>
                </>
            ),
            acceptLabel: 'Accept',
            acceptButtonType: 'Primary',
        });

        if (confirm && model) {
            await dialogManager.showLoadingWhile(acceptMutation(model.id));
        }
    }, [acceptMutation, dialogManager, model]);

    const menuItems: (MyMenuButtonItem | false)[] = useMemo(
        () => [
            !!model && {
                label: 'Clone',
                IconLeft: Icons.Clone,
                onClick: () => setShowCloneModal(true),
            },
            model?.isArchived
                ? {
                      label: `unarchive quote`,
                      IconLeft: Icons.Undo,
                      onClick: unarchive,
                  }
                : {
                      label: `Archive quote`,
                      IconLeft: Icons.Archive,
                      onClick: archive,
                  },
        ],
        [archive, model, unarchive],
    );

    return (
        <MyModal
            className="QuoteDetailModal"
            close={close}
            isLoading={isLoading}
            isError={isError}
            mobileTitle={model?.context.workflow.name}
            header={
                <>
                    <div className="QuoteDetailModal__BreadcrumbWrapper">
                        <Link
                            className="QuoteDetailModal__Breadcrumb"
                            to={`/workflows/${model?.context.workflow.id}`}
                        >
                            {model?.context.workflow.name}
                        </Link>
                        <Icons.ChevronRight className="icon" />
                        <Link
                            className="QuoteDetailModal__Breadcrumb"
                            to={`/workflows/${model?.context.workflow.id}/${model?.context.deal.id}`}
                        >
                            {model?.context.deal.tuid}
                        </Link>
                    </div>
                    <PageHeader
                        className="QuoteDetailModal__PageHeader"
                        title="Quote"
                        titleContext={
                            <div className="QuoteDetailModal__PageHeader__TitleContext">
                                <div className="QuoteDetailModal__PageHeader__Tuid">
                                    {model?.tuid}
                                </div>
                                <QuoteStatusBadge status={model?.status} />
                                {model?.isArchived && <ArchivedBadge size="large" />}
                            </div>
                        }
                    >
                        {model && (
                            <>
                                {model.context.totalQuantity > 0 && (
                                    <MyButton
                                        label="View PDF"
                                        buttonType="Hollow"
                                        LinkComponent={MyButtonLinkNewTab}
                                        onClick={() => setShowPdfModal(true)}
                                        IconLeft={Icons.File}
                                    />
                                )}

                                <MyMenuKebabButton
                                    className="QuoteDetailModal__MenuKebabButton"
                                    menuItems={menuItems}
                                />
                            </>
                        )}
                    </PageHeader>
                    {model?.context.pendingUpdates?.hasPendingUpdates && (
                        <OrderUpdatesMessagePanel
                            className="QuoteDetailModal__OrderUpdatesMessagePanel"
                            model={model}
                        />
                    )}
                </>
            }
        >
            {model && (
                <>
                    {model.status === QuoteStatus.Accepted && (
                        <>
                            <SectionHeader title="Summary" />
                            <div>
                                {model.context.orderLineCount}{' '}
                                {model.context.orderLineCount === 1 ? 'product' : 'products'}, (
                                {model.context.totalQuantity} total quantity)
                            </div>
                            <div>
                                {model.totalSellPriceOverride !== null && (
                                    <span className="QuoteDetailModal__SummaryPanel__OriginalPrice">
                                        {formatCurrency(model.totalSellPrice)}
                                    </span>
                                )}

                                <span className="QuoteDetailModal__SummaryPanel__Price">
                                    {formatCurrency(
                                        model.totalSellPriceOverride ?? model.totalSellPrice,
                                    )}
                                </span>
                            </div>
                        </>
                    )}

                    <div className="QuoteDetailModal__DetailsPanel">
                        <SectionHeader
                            title="Details"
                            autoMargin={model.status === QuoteStatus.Accepted}
                        />
                        <PropertyContainer layout="table">
                            <PropertyDisplay
                                label="Description"
                                verticalAlign="top"
                                value={
                                    <>
                                        {!model.isReadOnly && (
                                            <MyButton
                                                className="QuoteDetailModal__DetailsPanel__EditButton"
                                                label="Edit"
                                                buttonType="Hollow"
                                                size="small"
                                                onClick={() => setShowEditModal(true)}
                                            />
                                        )}
                                        <div>{model.description}</div>
                                    </>
                                }
                            />
                        </PropertyContainer>
                    </div>

                    <SectionHeader
                        title="Products"
                        autoMargin
                    />
                    <QuoteProductList order={model} />

                    {model.context.orderLines.length > 0 && model.status !== QuoteStatus.Accepted && (
                        <div className="QuoteDetailModal__Footer">
                            <div className="QuoteDetailModal__Footer__Content">
                                <div className="QuoteDetailModal__Footer__Content__TotalQuantity">
                                    {model.context.totalQuantity}{' '}
                                    {model.context.totalQuantity === 1 ? 'product' : 'products'},
                                    total
                                </div>
                                {model.totalSellPriceOverride !== null && (
                                    <div className="QuoteDetailModal__Footer__Content__OriginalPrice">
                                        {formatCurrency(model.totalSellPrice)}
                                    </div>
                                )}

                                <div className="QuoteDetailModal__Footer__Content__Price">
                                    {formatCurrency(
                                        model.totalSellPriceOverride ?? model.totalSellPrice,
                                    )}

                                    {/* Edit price button for quotes only */}
                                    {!model.isReadOnly && (
                                        <MyButton
                                            className="QuoteDetailModal__Footer__Content__Price__EditButton"
                                            IconLeft={Icons.Edit}
                                            title="Edit price"
                                            buttonType="None"
                                            size="small"
                                            onClick={() => setShowPriceEditModal(true)}
                                        />
                                    )}
                                </div>
                            </div>
                            {!model.isReadOnly && model.context.totalQuantity > 0 && (
                                <div className="QuoteDetailModal__Footer__AcceptWrapper">
                                    {hasErrors && (
                                        <Tooltip
                                            title="One or more products have errors"
                                            arrow
                                            placement="top"
                                        >
                                            <div className="errorIcon">
                                                <Icons.Warning />
                                            </div>
                                        </Tooltip>
                                    )}
                                    <MyButton
                                        label="Accept Quote"
                                        buttonType="Primary"
                                        LinkComponent={MyButtonLinkNewTab}
                                        onClick={accept}
                                        disabled={hasErrors}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    {showEditModal && (
                        <QuoteEditModal
                            order={model}
                            close={() => setShowEditModal(false)}
                        />
                    )}
                    {showCloneModal && (
                        <OrderCloneModal
                            order={model}
                            close={() => setShowCloneModal(false)}
                        />
                    )}
                    {showPdfModal && (
                        <QuotePdfModal
                            model={model}
                            close={() => setShowPdfModal(false)}
                        />
                    )}
                    {showPriceEditModal && (
                        <QuotePriceEditModal
                            order={model}
                            close={() => setShowPriceEditModal(false)}
                        />
                    )}
                </>
            )}
        </MyModal>
    );
}
