import FilterStrip, { FilterStripConfig } from 'components/FilterStrip/FilterStrip';
import { FilterInstance } from 'components/FilterStrip/FilterTypes';
import MyButton from 'components/MyButton/MyButton';
import React, { useCallback, useState } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import { AutocompleteFilterConfig } from '../FilterStrip/Filters/AutocompleteFilter';
import { DateFilterConfig } from '../FilterStrip/Filters/DateFilter';
import { SearchFilterConfig } from '../FilterStrip/Filters/SearchFilter';
import { SelectFilterConfig } from '../FilterStrip/Filters/SelectFilter';
import { TextFilterConfig } from '../FilterStrip/Filters/TextFilter';
import { ToggleFilterConfig } from '../FilterStrip/Filters/ToggleFilter';
import {
    applyAutocompleteFilter,
    applyDateFilter,
    applySearchFilter,
    applySelectFilter,
    applyTextFilter,
    applyToggleFilter,
} from './applyFilter';
import './FrontendFilterStrip.scss';
/** Union of filter configs for all the available filter types
 * Each filter type can define its own properties in addition to the base properties
 */
export type FrontendFilterStripConfig<T> = (
    | (TextFilterConfig & { getField: (item: T) => string | undefined | null })
    | (SearchFilterConfig & { getFields: (item: T) => (string | undefined | null)[] })
    | (SelectFilterConfig & { getField: (item: T) => string | undefined | null })
    | (AutocompleteFilterConfig & { getField: (item: T) => string | undefined | null })
    | (DateFilterConfig & { getField: (item: T) => string | undefined | null })
    | (ToggleFilterConfig & { getField: (item: T) => string | undefined | null })
) & {
    applyFilter?:
        | false
        | ((config: FrontendFilterStripConfig<T>, value: string, item: T) => boolean);
    onChange?: (config: FilterStripConfig<T>, value: string) => void;
};

export function defaultApplyFilter<T>(
    config: FrontendFilterStripConfig<T>,
    value: string,
    item: T,
) {
    switch (config.type) {
        case 'text':
            return applyTextFilter(config, value, item);
        case 'search':
            return applySearchFilter(config, value, item);
        case 'select':
            return applySelectFilter(config, value, item);
        case 'autocomplete':
            return applyAutocompleteFilter(config, value, item);
        case 'date':
            return applyDateFilter(config, value, item);
        case 'toggle':
            return applyToggleFilter(config, value, item);
        default:
            throw new Error(`No default applyFilter function for filter config`, config);
    }
}

export function FrontendFilterBuilder<T>() {
    const _filters: Array<FrontendFilterStripConfig<T>> = [];
    return {
        filter(f: false | FrontendFilterStripConfig<T>) {
            if (f) {
                // Display filters by default, unless explicitly set to false
                if (f.isSticky === undefined) {
                    f.isSticky = true;
                }
                _filters.push(f);
            }
            return this;
        },
        build() {
            return _filters;
        },
    };
}

export default function FrontendFilterStrip<T>({
    className,
    children,
    data,
    filters: filterDefs,
    onChange,
    allowReset = true,
    onRefresh,
    isRefreshing,
}: {
    className?: string;
    children?: React.ReactNode;
    data?: T[];
    filters: FrontendFilterStripConfig<T>[];
    onChange: (filteredData: T[] | undefined) => void;
    allowReset?: boolean;
    onRefresh?: () => void;
    isRefreshing?: boolean;
}) {
    const [filteredData, setFilteredData] = useState<T[] | undefined>([]);

    const applyFilters = useCallback(
        (activeFilters: FilterInstance<FrontendFilterStripConfig<T>>[]) => {
            const _filteredData: T[] | undefined = !data
                ? data
                : activeFilters.reduce(
                      (result, filter) => {
                          const func =
                              filter.config.applyFilter === false
                                  ? undefined
                                  : filter.config.applyFilter ?? defaultApplyFilter;
                          if (func) {
                              result = result.filter(item =>
                                  func(filter.config, filter.value, item),
                              );
                          }
                          filter.config.onChange?.(filter.config, filter.value);
                          return result;
                      },
                      [...data],
                  );
            setFilteredData(_filteredData);
            onChange(_filteredData);
        },
        [data, onChange],
    );

    return (
        <FilterStrip
            className={coalesceClassNames('FrontendFilterStrip', className)}
            filterDefs={filterDefs}
            allowReset={allowReset}
            onRefresh={onRefresh}
            isRefreshing={isRefreshing}
            onChange={applyFilters}
            filterResetComponent={({ resetFilters }) => (
                <div className="FrontendFilterStrip__FilteredResetPanel">
                    Showing {filteredData?.length ?? 0} of {data?.length ?? 0} items
                    <MyButton
                        className="FrontendFilterStrip__ResetButton"
                        label="Reset filters"
                        buttonType="LinkButton"
                        onClick={resetFilters}
                    />
                </div>
            )}
        >
            {children}
        </FilterStrip>
    );
}
