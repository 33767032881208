import { ListSubheader, MenuItem } from '@mui/material';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';
import React, { ReactNode, useMemo } from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';

import { WorkflowPhaseDisplay } from 'features/deals/enums/WorkflowPhase';
import { WorkflowDetail } from 'features/deals/models/WorkflowDetail';
import { WorkflowStatus } from 'features/deals/models/WorkflowStatus';
import WorkflowStatusIcon from '../WorkflowStatusIcon/WorkflowStatusIcon';
import './WorkflowStatusSelect.scss';

export type WorkflowStatusSelectProps = {
    workflow: WorkflowDetail;
    value: string;
    handleInput?: (value?: WorkflowStatus) => void;
    disabled?: boolean;
} & SelectProps;

export default function WorkflowStatusSelect({
    /** the selected option id */
    value,
    /** WorkflowDetail which we use to get the statuses from */
    workflow,
    handleInput,
    disabled,
    ...props
}: WorkflowStatusSelectProps) {
    const onChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
        const id = e.target.value as string;
        const statusValue = workflow.context.statuses.find(v => v.id === id);
        if (handleInput) {
            handleInput(statusValue);
        } else if (props.onChange) {
            props.onChange(e, child);
        }
    };

    const selectedValue = useMemo(
        () => workflow.context.statuses.find(v => v.id === value),
        [value, workflow.context.statuses],
    );

    return (
        <Select
            {...props}
            value={value}
            className={coalesceClassNames(
                'WorkflowStatusSelect',
                props.className && `${props.className}__Select`,
            )}
            size="small"
            onChange={onChange}
            MenuProps={{
                className: 'WorkflowStatusSelect__Menu',
            }}
            renderValue={() => (
                <div className="WorkflowStatusSelect__WithIcon">
                    <WorkflowStatusIcon status={selectedValue} />
                    {selectedValue?.name}
                </div>
            )}
            disabled={disabled}
        >
            {workflow.context.statuses.reduce<ReactNode[]>((acc, opt, index) => {
                if (index === 0 || opt.phase !== workflow.context.statuses[index - 1].phase) {
                    acc.push(
                        <ListSubheader key={opt.phase}>
                            <div className="WorkflowStatusSelect__WithIcon">
                                <WorkflowStatusIcon status={opt} />
                                {WorkflowPhaseDisplay.display(opt.phase)}
                            </div>
                        </ListSubheader>,
                    );
                }
                acc.push(
                    <MenuItem
                        key={index}
                        value={opt.id}
                    >
                        {opt.name}
                    </MenuItem>,
                );
                return acc;
            }, [])}
        </Select>
    );
}
