import ErrorContent from 'components/ErrorContent/ErrorContent';
import LinkOrDiv from 'components/LinkOrDiv/LinkOrDiv';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import DndContainer from 'components/ReactSmoothDnd/DndContainer';
import Draggable from 'components/ReactSmoothDnd/Draggable';
import React, { useCallback, useEffect, useMemo } from 'react';
import { DropResult } from 'smooth-dnd';
import coalesceClassNames from 'utils/coalesceClassNames';
import './Kanban.scss';

export type KanbanColumnDefinition<T, U = void> = {
    id: string;
    title: React.ReactFragment;
    /** Cards can be dragged between columns with the same group */
    group?: string;
    /** A filter function used when selecting the items that appear in this column
     * If a card matches multiple columns it will be added to the first one
     */
    cardSelector: (item: T) => boolean;
} & (U extends void // Only require data if a data object is passed in
    ? { data?: never }
    : { data: U });

export default function Kanban<T, U = void>({
    className,
    data,
    columns,
    renderCard,
    cardLinkTo,
    onCardClick,
    onCardDrop,
    isLoading,
    isError,
    isRefreshing,
}: {
    className?: string;
    data?: T[];
    columns: KanbanColumnDefinition<T, U>[];
    renderCard?: (item: T, column: KanbanColumnDefinition<T, U>) => React.ReactFragment;
    cardLinkTo?: (item: T) => string | undefined;
    onCardClick?: (item: T) => void;
    onCardDrop?: (item: T, destColumn: KanbanColumnDefinition<T, U>, after: T | undefined) => void;
    isLoading?: boolean;
    isError?: boolean;
    isRefreshing?: boolean;
}) {
    /** Prevent the board from showing the refreshing state when the user is dragging a card */
    const [suppressRefreshState, setSuppressRefreshState] = React.useState(false);

    const handleCardDrop = useCallback(
        (item: T, destColumn: KanbanColumnDefinition<T, U>, after: T | undefined) => {
            onCardDrop?.(item, destColumn, after);
            setSuppressRefreshState(true);
        },
        [onCardDrop],
    );

    /** Clear the suppressRefreshState flag when refresh has completed */
    useEffect(() => {
        if (!isRefreshing) {
            setSuppressRefreshState(false);
        }
    }, [isRefreshing]);

    /** A hash of cards in each column as dictated by the column selector */
    const dataByColumn = useMemo(() => {
        // create a hash of column ids to arrays of items
        const hash = columns.reduce((res, col) => {
            res[col.id] = [];
            return res;
        }, {} as Record<string, T[]>);

        // loop through data and add item to first column that matches
        data?.forEach(item => {
            const col = columns.find(c => c.cardSelector(item));
            if (col) {
                hash[col.id].push(item);
            }
        });
        return hash;
    }, [columns, data]);

    return (
        <div
            className={coalesceClassNames(
                'Kanban',
                !suppressRefreshState && isRefreshing && 'Kanban--refreshing',
                className,
            )}
        >
            {isLoading ? (
                <MyLinearProgress />
            ) : isError ? (
                <ErrorContent />
            ) : (
                columns.map(col => (
                    <Column
                        key={col.id}
                        column={col}
                        data={dataByColumn[col.id]}
                        renderCard={renderCard}
                        cardLinkTo={cardLinkTo}
                        onCardClick={onCardClick}
                        onCardDrop={handleCardDrop}
                    />
                ))
            )}
        </div>
    );
}

function Column<T, U>({
    column,
    data,
    renderCard,
    cardLinkTo,
    onCardClick,
    onCardDrop,
}: {
    column: KanbanColumnDefinition<T, U>;
    data: T[];
    renderCard?: (item: T, column: KanbanColumnDefinition<T, U>) => React.ReactFragment;
    cardLinkTo?: (item: T) => string | undefined;
    onCardClick?: (item: T) => void;
    onCardDrop?: (item: T, destColumn: KanbanColumnDefinition<T, U>, after: T | undefined) => void;
}) {
    const [isDroppable, setIsDroppable] = React.useState(false);

    const handleDragEnter = useCallback(() => {
        setIsDroppable(true);
    }, []);

    const handleDragLeave = useCallback(() => {
        setIsDroppable(false);
    }, []);

    const handleDrop = (result: DropResult) => {
        setIsDroppable(false);
        if (result.addedIndex !== null) {
            const _data = [...data];
            if (result.removedIndex !== null) {
                _data.splice(result.removedIndex, 1);
            }
            const cardAbove = result.addedIndex > 0 ? _data[result.addedIndex - 1] : undefined;
            onCardDrop?.(result.payload, column, cardAbove);
        }
    };

    return (
        <div className={coalesceClassNames('Kanban__Column', `Kanban__Column--${column.id}`)}>
            <div className="Kanban__Column__Header">
                <h3 className="Kanban__Column__Header__Title">{column.title}</h3>
                <div className="Kanban__Column__Header__Count">{data.length}</div>
            </div>
            <div
                className={coalesceClassNames(
                    'Kanban__Column__DragArea',
                    isDroppable && 'Kanban__Column__DragArea--Droppable',
                )}
            >
                <DndContainer
                    groupName="kanban"
                    getChildPayload={i => data[i]}
                    onDrop={handleDrop}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    dragClass="DRAGGING"
                    dropClass="DROPPING"
                >
                    {data.length === 0 && <div className="Kanban__Column__Empty"></div>}
                    {data.map((item, i) => (
                        <Draggable
                            key={i}
                            disableTouch
                        >
                            <LinkOrDiv
                                className="Kanban__Card"
                                to={cardLinkTo?.(item)}
                                onClick={onCardClick ? () => onCardClick(item) : undefined}
                                suppressMouseDown
                            >
                                {renderCard?.(item, column)}
                            </LinkOrDiv>
                        </Draggable>
                    ))}
                </DndContainer>
            </div>
        </div>
    );
}
