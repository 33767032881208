import Icons from 'Icons';
import DataTable, { ColumnBuilder } from 'components/DataTable/DataTable';
import MyButton from 'components/MyButton/MyButton';
import MyLinearProgress from 'components/MyLinearProgress/MyLinearProgress';
import SectionHeader from 'components/SectionHeader/SectionHeader';
import DocumentDetailModal from 'features/documents/components/DocumentDetailModal/DocumentDetailModal';
import DocumentFileIcon from 'features/documents/components/DocumentFileIcon/DocumentFileIcon';
import DocumentUploadModal from 'features/documents/components/DocumentUploadModal/DocumentUploadModal';
import documentsApi from 'features/documents/documents.api';
import { DocumentFriendlyTypeDisplay } from 'features/documents/enums/DocumentFriendlyType';
import { DocumentFile } from 'features/documents/models/DocumentFile';
import { DocumentUploadResultDocument } from 'features/documents/models/DocumentUploadResult';
import ordersApi from 'features/orders/orders.api';
import { useOrderPresentation } from 'features/orders/providers/OrderPresentation';
import React, { useCallback, useMemo, useState } from 'react';
import { formatDateTimeRelative } from 'utils/dateHelpers';
import { isEmpty } from 'utils/helpers';
import './OrderAttachments.scss';

/** Display and manage documents attached to both Quotes and PurchaseOrders */
export default function OrderAttachments({
    orderId,
    isReadOnly,
}: {
    orderId: number;
    isReadOnly: boolean;
}) {
    const { entityTitle } = useOrderPresentation();

    const query = ordersApi.useOrderDocumentsQuery(orderId);
    const [attachMutation] = ordersApi.useOrderDocumentAttachMutation();
    const [archiveMutation] = ordersApi.useOrderDocumentArchiveMutation();

    const categoriesQuery = documentsApi.useProductDocumentCategoryListQuery();
    const firstCategoryId = useMemo(
        () => categoriesQuery.data?.[0]?.id ?? 0,
        [categoriesQuery.data],
    );

    const [detailDocId, setDetailDocId] = useState<number>();
    const detailModel = useMemo(
        () => detailDocId && query.data?.data.find(d => d.id === detailDocId),
        [detailDocId, query.data?.data],
    );

    /** After the document has been uploaded, attach it to an order */
    const attachUploadedDocument = useCallback(
        (document: DocumentUploadResultDocument) => {
            return attachMutation({
                document,
                orderId,
                categoryId: firstCategoryId,
            }).unwrap();
        },
        [attachMutation, firstCategoryId, orderId],
    );

    const handleArchive = useCallback(
        async (documentId: number) => {
            return archiveMutation({
                orderId,
                documentId,
            }).unwrap();
        },
        [archiveMutation, orderId],
    );

    const [showUpload, setShowUpload] = useState(false);

    return (
        <div className="OrderAttachments">
            <SectionHeader
                title="Attachments"
                autoMargin
            />

            <AttachmentsTable
                data={query.data?.data}
                isLoading={query.isLoading}
                isError={query.isError}
                onRowClick={(doc: DocumentFile) => setDetailDocId(doc.id)}
            />

            {!isReadOnly && (
                <MyButton
                    className="OrderAttachments__UploadButton"
                    label="Add attachment"
                    buttonType="Hollow"
                    IconLeft={Icons.Plus}
                    size="small"
                    onClick={() => setShowUpload(true)}
                />
            )}

            {showUpload && categoriesQuery && (
                <DocumentUploadModal
                    directUpload={true}
                    afterUpload={attachUploadedDocument}
                    mobileTitle={entityTitle}
                    close={() => setShowUpload(false)}
                />
            )}

            {detailModel && (
                <DocumentDetailModal
                    model={detailModel}
                    mobileTitle={entityTitle}
                    isReadOnly={isReadOnly}
                    onArchive={handleArchive}
                    close={() => setDetailDocId(undefined)}
                />
            )}
        </div>
    );
}

function AttachmentsTable({
    data,
    isLoading,
    isError,
    onRowClick,
}: {
    data?: DocumentFile[];
    isLoading?: boolean;
    isError?: boolean;
    onRowClick?: (item: DocumentFile) => void;
}) {
    const columns = useMemo(
        () =>
            ColumnBuilder<DocumentFile>()
                .column({
                    key: 'name',
                    label: 'Document name',
                    isSortable: true,
                    getValue: item => item.name,
                    renderValue: (val, doc) => (
                        <div className="OrderAttachments__AttachmentsTable__NameCell">
                            <DocumentFileIcon type={doc.type} />
                            {val}
                        </div>
                    ),
                })
                .column({
                    key: 'type',
                    label: 'Type',
                    isSortable: true,
                    getValue: item => item.type,
                    renderValue: val => DocumentFriendlyTypeDisplay.display(val),
                })
                .column({
                    key: 'created_at',
                    label: 'Created',
                    isSortable: true,
                    defaultSort: 'DESC',
                    getValue: item => item.created_at,
                    renderValue: val => formatDateTimeRelative(val),
                })
                .build(),
        [],
    );

    if (!isLoading && !isError && isEmpty(data)) {
        return null;
    }

    return (
        <div className="OrderAttachments__AttachmentsTable">
            {isLoading ? (
                <MyLinearProgress />
            ) : (
                <DataTable
                    className="OrderAttachments__AttachmentsTable__DataTable"
                    data={data}
                    isError={isError}
                    columns={columns}
                    onRowClick={onRowClick}
                    showHeader="none"
                    rowStyle="skinny"
                    zebra={'dark'}
                />
            )}
        </div>
    );
}
