import LayoutHeader from 'components/LayoutHeader/LayoutHeader';
import PageHeader from 'components/PageHeader/PageHeader';
import DocumentsTable from 'features/documents/components/DocumentsTable/DocumentsTable';
import documentsApi from 'features/documents/documents.api';
import useApiTagInvalidate from 'hooks/useApiTagInvalidate';
import React from 'react';
import { Outlet } from 'react-router';
import { ApiTagType } from 'services/api';
import { usePageTitle } from 'utils/locationHelpers';

export default function DocumentsPage() {
    usePageTitle('Documents');
    const query = documentsApi.useManufacturerDocumentListQuery();

    // Refresh data when the page is loaded
    const refreshData = useApiTagInvalidate([ApiTagType.Documents], { onMount: true });

    return (
        <>
            <LayoutHeader>
                <PageHeader title="Documents" />
            </LayoutHeader>

            <DocumentsTable
                data={query.data}
                isLoading={query.isLoading}
                isError={query.isError}
                onRefresh={refreshData}
                isRefreshing={query.isFetching}
            />

            <Outlet />
        </>
    );
}
