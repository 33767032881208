import StatusPill, { StatusPillSize } from 'components/StatusPill/StatusPill';
import QuoteStatus, { QuoteStatusDisplay } from 'features/quotes/enums/QuoteStatus';
import React from 'react';
import coalesceClassNames from 'utils/coalesceClassNames';
import './QuoteStatusBadge.scss';

export default function QuoteStatusBadge({
    status,
    size = 'large',
    className,
}: {
    status?: QuoteStatus;
    size?: StatusPillSize;
    className?: string;
}) {
    return status ? (
        <StatusPill
            className={coalesceClassNames('QuoteStatusBadge', status, className)}
            size={size}
        >
            {QuoteStatusDisplay.display(status)}
        </StatusPill>
    ) : null;
}
